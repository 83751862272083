/* Models */
import { Model } from '@vuex-orm/core'
import reduce from 'lodash/reduce'
import Customer from './Customer'
import GasMeter from './GasMeter'
import Meter from './Meter'

/* Utils */
import WaterMeter from '~/orm/models/WaterMeter'

export default class Site extends Model {
    static entity = 'sites'
    // static primaryKey = 'siteId'

    static apiConfig = {
        dataKey: 'data',
        params: {
            includeUserGenerated: true,
        },
        actions: {
            addOrUpdateSite(site, createAsset = false) {
                if (site.id) {
                    return this.updateSite(site)
                } else {
                    return this.addSite(site, createAsset)
                }
            },
            addSite(site, createAsset = false) {
                return this.post('/onboarding/sites/with-asset', { site, createAsset }, {
                    baseURL: '/',
                    dataTransformer: ({ data: { site } }) => site,
                }).then(({ response: { data } }) => data)
            },
            updateSite(site) {
                return this.put(`/sites/${site.id}`, site, { dataTransformer: ({ data }) => data }).then(({ response: { data } }) => ({ site: data }))
            },
            deleteSite(siteId, assetIds = []) {
                return this.delete(`/onboarding/sites/${siteId}`, {
                    dataKey: null,
                    baseURL: '/',
                    params: { assetIds },
                }, { delete: siteId })
            },
        },
    }

    static fields() {
        return {
            id: this.attr(null),
            icon: this.attr('$building'),
            customerId: this.attr(null),
            name: this.attr(''),
            friendlyName: this.attr(''),
            presetId: this.attr(null),
            // metas: this.morphMany(Meta, 'entity_id', 'entity_name'),
            waterMeters: this.hasMany(WaterMeter, 'siteId'),
            gasMeters: this.hasMany(GasMeter, 'siteId'),
            meters: this.hasMany(Meter, 'siteId'),
            customer: this.belongsTo(Customer, 'customerId'),
            editable: this.boolean(true),
            address1: this.attr(null),
            address2: this.attr(null),
            city: this.attr(null),
            postCode: this.attr(null),
            details: this.attr({}),
            realName: this.attr(null),
            latitudeLongitude: this.attr(null),
            size: this.attr(null),
            address: this.attr({}),
            isUserGenerated: this.attr(false),
            country: this.attr({}),
            color: this.attr(null),
            backupColor: this.attr(null),
            electricityTotalKw: this.number(0),
            gasTotalKw: this.number(0),
            waterTotalKw: this.number(0),
        }
    }

    static i = 0

    get siteId() {
        return this.id
    }

    static beforeCreate(model) {
        // if(model.id.includes('$')){
        //     console.log('create site:', model)
        //     stackTrace()
        // }

        if (!model.details) {
            model.details = {}
        }
        if (model.$store().$cookie.get('anon')) {
            model.realName = model.name
            model.name = `Site ${++Site.i}`
        }
    }

    get position() {
        if (this.latitudeLongitude) {
            const position = this.latitudeLongitude.split(',')
            return { lat: Number(position[0]), lng: Number(position[1]) }
        } else {
            return {
                lat: 0,
                lng: 0,
            }
        }
    }

    get location() {
        return this.position
    }

    get entity() {
        return Site.entity
    }

    get fullAddress() {
        return `${this.name}, ${this.address1} ${this.address2} ${this.city} ${this.postCode}`
    }

    get children() {
        let children = [
            ...this.meters,
            ...this.gasMeters,
            ...this.waterMeters,
        ]
        if (children.length === 0) {
            children = this.getChildren()
        }
        return children
    }

    /**
     * Get all relationship mlodels
     */
    get relationships() {
        // get meters
        const meters = Meter.query()
            .where('siteId', this.id)
            .with('circuits')
            .get()
        // gas meters
        const gasMeters = GasMeter.query()
            .where('siteId', this.id)
            .with('pipes')
            .get()
        const waterMeters = WaterMeter.query()
            .where('siteId', this.id)
            .with('water-pipes')
            .get()
        // circuits
        const circuits = reduce(
            meters,
            (reducedCircuits, meter) => {
                reducedCircuits.push(...meter.circuits)
                return reducedCircuits
            },
            [],
        )
        // pipes
        const pipes = reduce(
            gasMeters,
            (reducedPipes, gasMeter) => {
                reducedPipes.push(...gasMeter.pipes)
                return reducedPipes
            },
            [],
        )

        // water pipes
        const waterPipes = reduce(
            waterMeters,
            (reducedPipes, waterMeter) => {
                reducedPipes.push(...waterMeter.pipes)
                return reducedPipes
            },
            [],
        )

        return {
            meters,
            gasMeters,
            waterMeters,
            circuits,
            pipes,
            waterPipes,
        }
    }

    getName() {
        return this.name
    }

    getChildren(scope = '') {
        switch (scope) {
            case 'electricity':
                return Meter.query().where('siteId', this.id).get()
            case 'gas':
                return GasMeter.query().where('siteId', this.id).get()
            case 'water':
                return WaterMeter.query().where('siteId', this.id).get()
            default:
                return [
                    ...Meter.query().where('siteId', this.id).get(),
                    ...GasMeter.query().where('siteId', this.id).get(),
                    ...WaterMeter.query().where('siteId', this.id).get(),
                ]
        }
    }

    getUnitData(unit, commodity, resolution) {
        if (!unit) {
            console.warn('Unit is required')
            return
        }
        if (!this.id) {
            console.warn('Site ID is missing for some alien reason.')
            return
        }
        return this.$store().$cache.getUnitData(unit, this, commodity, resolution)
    }

    /**
     * JSON serializer
     * responsible to define what data will be serialized.
     * @return {Object} json
     */
    toJSON() {
        return {
            ...this.$toJson(),
            id: this.id,
            entity: this.entity,
            children: this.children,
            name: this.getName(),
        }
    }
}
