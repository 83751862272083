/* Models */
import { Model } from '@vuex-orm/core'
import { get, groupBy } from 'lodash'
import Meta from './Meta'
import Note from './Note'
import Asset from './Asset'
import Meter from './Meter'
import Preset from './Preset'
import { COMMODITY_TYPES } from '~/utils/entities'
/* Utils */

export default class Circuit extends Model {
    static entity = 'circuits'

    static fields() {
        return {
            meterId: this.attr(null),
            id: this.attr(null),
            parentId: this.attr(null),
            icon: this.attr('$bolt'),
            name: this.attr(''),
            friendlyName: this.attr(''),
            presetId: this.attr(null),
            color: this.attr(null),
            backupColor: this.attr(null),
            editable: this.boolean(true),
            virtual: this.boolean(false), // flag for virtual circuits (eg: Virtual Main Incomer)
            totalKwData: this.number(0),
            metas: this.morphMany(Meta, 'entity_id', 'entity_name'),
            // meter: this.belongsTo(Meter, 'meterId'),
            assets: this.hasMany(Asset, 'id'),
            note: this.hasOne(Note, 'id'),
            preset: this.belongsTo(Preset, 'presetId'),
            rendered: this.boolean(false),
            isMainIncomer: this.boolean(false),
            startTime: this.attr(null),
            endTime: this.attr(null),
            createdAt: this.attr(null),
            totalKwh: this.attr(0),
            childrenCount: this.attr(0),
            aggregates: this.attr({}),
            manufacturer: this.attr(null),
            sampleRate: this.attr(null),
            hasData: this.boolean(true),
        }
    }

    static virtualId(presetId) {
        return 'V' + presetId
    }

    get circuitId() {
        return this.id
    }

    static apiConfig = {
        url: 'clamps',
        params: {
            commodity: ['power'],
        },
        dataTransformer: ({ data }) => {
            if (Array.isArray(data?.data)) {
                const groupedByParentId = groupBy(data.data, 'parentId')
                // TODO: update models in the database to come with the childrenCount prop from the API so we don't have to do this every time
                return data.data.map(circuit => {
                    let childrenCount = 0
                    if (groupedByParentId[circuit.id]) {
                        childrenCount = groupedByParentId[circuit.id].length
                    }
                    circuit.childrenCount = childrenCount
                    return circuit
                })
            }
            return data
        },
    }

    get entity() {
        return Circuit.entity
    }

    get site() {
        return get(this, 'relationships.site', null)
    }

    get meter() {
        return Meter.find(this.meterId)
    }

    get siteId() {
        return get(this, 'site.siteId', null)
    }

    get siteName() {
        return get(this, 'site.name')
    }

    /**
    * Get circuit parent
    */
    get parent() {
        if (this.virtual) {
            return Preset.find(this.presetId)
        }
        if (this.parentId) {
            return Circuit.find(this.parentId)
        }
        return this.getMeter()
    }

    /**
    * Get circuit children
    */
    get children() {
        return this.getChildren()
    }

    /**
    * Get circuit siblings
    * Other circuits under the same parent
    */
    get siblings() {
        if (this.virtual) {
            return this.parent.circuitsChildren.filter(c => c.id !== this.id)
        } else {
            return this.parent.children.filter(c => c.id !== this.id && c.parentId === this.parentId)
        }
    }

    /**
    * Get model relationships
    *
    * @return {Object}
    */
    get relationships() {
        // meter
        const meter = this.getMeter()
        // site
        const site = meter && meter.parent

        return {
            site,
            meter,
            parent: this.parent,
        }
    }

    getName({ friendly } = {}) {
        let name
        if (this.isMainIncomer) {
            name = this.parent.getName()
        } else if (this.virtual) {
            name = this.parent.getName({ friendly })
        } else if (friendly && this.friendlyName) {
            name = this.friendlyName
        } else {
            name = this.name
        }

        if (!this.hasData) {
            name = `${name} (No data)`
        }

        return name
    }

    getChildren() {
        if (this.childrenCount === 0) return []
        return Circuit.query()
            .where('parentId', this.id)
            .orderBy('totalKwData', 'desc')
            .get()
    }

    getMeter() {
        return this.meter || Meter.find(this.meterId)
    }

    isNonHalfHourly() {
        return this.isMainIncomer && this.manufacturer === 10
    }

    getUnitData(unit, commodity, resolution) {
        if (!unit) {
            console.warn('Unit is required')
            return
        }
        if (!this.id) {
            console.warn('Circuit ID is missing for some alien reason.')
            return
        }
        if (commodity !== COMMODITY_TYPES.electricity) {
            console.warn('wrong commodity is being used')
        }
        // return this.$store().$cache.getUnitDataForCircuit(unit, this.circuit)
        return this.$store().$cache.getUnitData(unit, this, commodity, resolution)
    }

    /**
     * JSON serializer
     * responsible to define what data will be serialized.
     * @return {Object} json
     */
    toJSON() {
        return {
            ...this.$toJson(),
            entity: this.entity,
            id: this.id,
            children: this.children,
        }
    }
}
