import { max, min } from 'lodash'
import {
    dateToPoint,
    difference,
    differenceInWeeks,
    differenceInHours,
    differenceInDays,
    differenceInMonths,
    differenceInYears,
    pointToDate,
} from './date'
import moment from '~/composables/useMoment'
import { intersect } from '~/utils/math'
export const SHIFTS = ['hour', 'day', 'isoWeek', 'customWeek', 'month', 'year', 'currentInterval'] // 'wh,

export const shiftToModel = {
    customWeek: 'week',
    hour: 'hour',
    day: 'day',
    week: 'week',
    month: 'month',
    year: 'year',
} // wh: 'day',

export function differenceInShifts({ shift, start, end, weekStartDay, shiftDuration }) {
    start = moment(start)
    end = moment(end)
    switch (shift) {
        case 'hour':
            return differenceInHours(end, start)
        // case 'wh':
        case 'day':
            return differenceInDays(end, start)
        case 'isoWeek':
            return differenceInWeeks(end, start)
        case 'customWeek': {
            const _start = start.clone()
            _start.startOfCustomWeek(weekStartDay)
            return differenceInWeeks(end, _start)
        }
        case 'month':
            return differenceInMonths(end, start)
        case 'year':
            return differenceInYears(end, start)
        case 'currentInterval':
            return Math.floor(end.diff(start, 'seconds') / shiftDuration)
        default:
            return NaN
    }
}

export function isValidShift({ shift, start, end, weekStartDay, shiftDuration }) {
    return differenceInShifts({ shift, start, end, weekStartDay, shiftDuration }) >= 1
}

export function canCompare({ shift, start, end, weekStartDay, shiftDuration }) {
    return differenceInShifts({ shift, start, end, weekStartDay, shiftDuration }) >= 2
}

/**
 * Shift backward
 *
 * @param {Object} { shift, time, weekStartDay }
 * @return {Object} { start, end }
 */
export function shiftBackward({ shift, period }) {
    if (shift === 'currentInterval') {
        const start = moment(period.start)
        const end = moment(period.end)
        const diff = difference(period.end, period.start)
        start.subtract(diff)
        end.subtract(diff)
        return { start, end }
    } else {
        return shiftPeriod({ shift, period }, 'backward')
    }
}
/**
 * Shift forward
 *
 * @param {Object} { shift, time, weekStartDay }
 * @return {Object} { start, end }
 */
export function shiftForward({ shift, period }) {
    // const isWh = shift === 'wh'
    if (shift === 'currentInterval') {
        const start = moment(period.start)
        const end = moment(period.end)
        const diff = difference(period.end, period.start)
        start.add(diff)
        end.add(diff)
        return { start, end }
    } else {
        return shiftPeriod({ shift, period }, 'forward')
    }
}

function shiftPeriod({ shift, period }, direction) {
    const isStandardInterval = !!(shiftToModel[period.interval])
    const start = moment(period.start)
    // const isWh = shift === 'wh'
    shift = shiftToModel[shift] || shift
    if (direction === 'forward') {
        start.add(1, shift)
    } else {
        start.subtract(1, shift)
    }
    const end = isStandardInterval
        ? moment(start).add(1, shiftToModel[period.interval])
        : moment(start).add(difference(period.end, period.start), 'ms')
    return { start, end }
}

export function shiftToLast({ shift, period, weekStartDay = 1 }) {
    let start = moment(period.start)
    let end = moment(period.end)

    if (shift === 'currentInterval') {
        const diff = difference(period.end, period.start)
        end = moment(period.max)
        start = moment(period.max).subtract(diff)
    } else {
        do {
            ({ start, end } = shiftForward({ shift, period: { ...period, start, end } }))
        } while (isValidShift({
            shift,
            start: end,
            end: period.max,
            weekStartDay,
        }))
    }
    return { start, end }
}

export function adjustTime({ shift, time, min, max, resolution, weekStartDay = 1 }) {
    let start = moment(time) // .add(1, 'minute')

    // we have to decide if end is going to be now, now rounded, or last point of data (max)
    let end = moment(max)

    const diffInShifts = differenceInShifts({ shift, start: time, end: max, weekStartDay })

    switch (shift) {
        case 'hour':
            start = start.startOf('hour')
            if (diffInShifts < 1) {
                start.subtract(1, 'hour')
            }
            end = moment(start).add(1, 'hour')
            break
        // case 'wh':
        //     start = start.startOf('day').add(7, 'hour')
        //     if (diffInShifts < 1) {
        //         start.subtract(1, 'day')
        //     }
        //     end = moment(start).add(12, 'hour')
        //     break
        case 'day':
            start = start.startOf('day')
            if (diffInShifts < 1) {
                start.subtract(1, 'day')
            }
            end = moment(start).add(24, 'hour')
            break
        case 'customWeek':
            start.startOfCustomWeek(weekStartDay)
            end = moment(start).add(1, 'week')
            break
        case 'isoWeek':
            start = start.startOf('isoWeek')
            if (diffInShifts < 1) {
                start.subtract(1, 'week')
            }
            end = moment(start).add(1, 'week')
            break
        case 'month':
            start = start.startOf('month')
            if (diffInShifts < 1) {
                start.subtract(1, 'month')
            }
            end = moment(start).add(1, 'month')
            break
        case 'ytd':
            start = moment().startOf('year')
            if (['month', 'year'].includes(resolution)) {
                end = moment(max).endOf('month')
                min = start
                max = end
            }
            break
        case 'year':
            start = start.startOf('year')
            if (diffInShifts < 1) {
                start.subtract(1, 'year')
            }
            end = moment(start).add(1, 'year')
            break
        case 'all':
            start = moment(min)
            if (['month', 'year'].includes(resolution)) {
                start.subtract(1, 'month')
                end = moment(max).endOf('month')
                min = start
                max = end
            }
            break
    }

    start = moment.max(start, min)
    end = moment.min(end, max)

    return {
        start,
        end,
    }
}

export function arrayOf(value = 0, length = 0) {
    return Array.from({ length }).fill(value)
}

/**
 * Get extreme points
 *
 * @param   {Date}  start Start date
 * @param   {Date}  min  Min date
 * @param   {Number}  resolution  [resolution description]
 * @param   {Date}  end End date
 *
 * @return  {Object} extremes
 */
export function extremePoints({ start, end = moment(), min, resolution }) {
    min = moment(min)
    start = moment(start)
    end = moment(end)

    if (min.isDST() && !start.isDST()) {
        start.subtract(1, 'h')
    }

    if (!min.isDST() && start.isDST()) {
        start.add(1, 'h')
    }

    if (min.isDST() && !end.isDST()) {
        end.subtract(1, 'h')
    }

    if (!min.isDST() && end.isDST()) {
        end.add(1, 'h')
    }

    return {
        pointStart: dateToPoint({ date: start, start: min, resolution }),
        pointEnd: dateToPoint({ date: end, start: min, resolution }) + 1,
    }
}

export function sliceDataForPeriod(data = [], { min, start, end, resolution } = {}, fill = false) {
    min = moment(min)
    start = moment(start)
    end = moment(end)

    if (min.isDST() && !start.isDST()) {
        start.subtract(1, 'h')
    }

    if (!min.isDST() && start.isDST()) {
        start.add(1, 'h')
    }

    if (min.isDST() && !end.isDST()) {
        end.subtract(1, 'h')
    }

    if (!min.isDST() && end.isDST()) {
        end.add(1, 'h')
    }

    let pointStart = dateToPoint({ date: start, start: min, resolution })

    let result = []

    // prefix with 0's
    while (pointStart < 0) {
        result = arrayOf(0, 0 - pointStart)
        pointStart = 0
    }
    const pointEnd = dateToPoint({ date: end, start: min, resolution }) + 1
    result = result.concat(data.slice(pointStart, pointEnd))
    // fill with 0's
    if (data.length < pointEnd) {
        result = result.concat(arrayOf(0, pointEnd - data.length))
    }

    return {
        pointStart,
        pointEnd,
        data: result,
    }
}

export function sliceDataForPeriodExp2(chart, period) {
    const data = {
        x: [],
        y: [],
    }
    let pointStart = 0
    let pointEnd = 0
    if (chart.calcdata?.[0]?.[0]?.trace) {
        const xAxis = chart.calcdata[0][0].trace._xA
        const yAxis = chart.calcdata[0][0].trace._yA
        // const xMax = chart._fullLayout._size.w
        const yMax = chart._fullLayout._size.h
        const cd = chart.calcdata[0]
        const len = cd[0].trace._length
        const xpx = new Array(len)
        const ypx = new Array(len)
        const x = cd[0].t.x
        const y = cd[0].t.y
        for (let j = 0; j < len; j++) {
            xpx[j] = xAxis.c2p(x[j])
            ypx[j] = yAxis.c2p(y[j])
        }
        const spx = xAxis.c2p(moment(period.start.format('YYYY-MM-DD HH:mm')).utc(true).valueOf())
        const epx = xAxis.c2p(moment(period.end.format('YYYY-MM-DD HH:mm')).utc(true).valueOf())
        function contains(pt) {
            const x = pt[0]
            const y = pt[1]
            return (!(x < spx || x > epx || y < 0 || y > yMax))
        }
        for (let i = 0; i < len; i++) {
            if (contains([xpx[i], ypx[i]])) {
                pointStart = pointStart || i
                pointEnd = i
                data.x.push(xAxis.c2d(x[i]))
                data.y.push(yAxis.c2d(y[i]))
            }
        }
        const addBitStart = (period.start.minutes() * 60) % period.resolution !== 0
        const addBitEnd = (period.end.minutes() * 60) % period.resolution !== 0
        if (addBitStart) {
            const minutesRatio = ((period.start.minutes() * 60) % period.resolution) / period.resolution
            const x1 = 0
            const y1 = yAxis.c2d(y[pointStart - 1] ?? 0)
            const x2 = 1
            const y2 = yAxis.c2d(y[pointStart])

            const x3 = minutesRatio
            const y3 = -1e9
            const x4 = minutesRatio
            const y4 = 1e9
            const intersection = intersect(x1, y1, x2, y2, x3, y3, x4, y4)
            data.x.unshift(period.start.format('YYYY-MM-DD HH:mm'))
            data.y.unshift(intersection.y)
        }
        const _end = moment(period.end.format('YYYY-MM-DD HH:mm')).subtract(period.resolution, 'seconds')
        if (moment(data.x[data.x.length - 1]).isBefore(_end)) {
            while (moment(data.x[data.x.length - 1]).isBefore(_end)) {
                data.x.push(moment(data.x[data.x.length - 1]).add(period.resolution, 'seconds').format('YYYY-MM-DD HH:mm'))
                data.y.push(null)
            }
        }
        if (addBitEnd) {
            const minutesRatio = ((period.end.minutes() * 60) % period.resolution) / period.resolution
            const x1 = 0
            const y1 = yAxis.c2d(y[pointEnd])
            const x2 = 1
            const y2 = yAxis.c2d(y[pointEnd + 1] ?? 0)

            const x3 = minutesRatio
            const y3 = -1e9
            const x4 = minutesRatio
            const y4 = 1e9
            const intersection = intersect(x1, y1, x2, y2, x3, y3, x4, y4)
            data.x.push(period.end.format('YYYY-MM-DD HH:mm'))
            data.y.push(intersection.y)
        }
    }
    return {
        pointStart,
        pointEnd,
        data,
    }
}

export function sliceDataForPeriodExp(data = [], { min, start, end, resolution } = {}, trace) {
    min = moment(min)
    start = moment(start)
    end = moment(end)
    console.log({
        min: min.isDST(),
        start: start.isDST(),
        end: end.isDST(),
    })
    let sd = 0
    let ed = 0
    if (min.isDST() && !start.isDST()) {
        console.log(1)
        sd = -1
        ed = -1
    }

    if (!min.isDST() && start.isDST()) {
        console.log(2)
        sd = 1
        ed = 1
    }

    if (min.isDST() && !end.isDST()) {
        console.log(3)
        sd = -1
        ed = -1
    }

    if (!min.isDST() && end.isDST()) {
        console.log(4)
        sd = 1
        ed = 1
    }

    let dstTransition = 0
    if ((start.isDST() && !end.isDST()) || (moment(start).add(sd, 'hours').isDST() && !moment(end).add(sd, 'hours').isDST())) {
        console.log('DST went off')
        dstTransition = -1
    } else if ((!start.isDST() && end.isDST()) || (!moment(start).add(ed, 'hours').isDST() && moment(end).add(ed, 'hours').isDST())) {
        console.log('DST went on')
        // ed = 1.5
        sd = 0
        dstTransition = 1
    }
    start.add(sd, 'hours')
    end.add(ed, 'hours')

    let pointStart = dateToPoint({ date: start, start: min, resolution })

    let y = []
    let x = []

    // prefix with 0's
    while (pointStart < 0) {
        y = arrayOf(0, 0 - pointStart)
        // x = arrayOf(0, 0 - pointStart)
        pointStart = 0
    }
    const pointEnd = dateToPoint({ date: end, start: min, resolution })
    y = y.concat(data.slice(pointStart, pointEnd))
    // x = x.concat(trace._x.slice(pointStart, pointEnd))
    // fill with 0's
    if (data.length < pointEnd) {
        y = y.concat(arrayOf(0, pointEnd - data.length))
    }

    if (dstTransition > 0) {
        y = y.filter(y => y)
        x = Array.from({ length: y.length }).map((_, k) => {
            const x = moment(trace._x[pointStart]).add(k * resolution, 'seconds') // .utc(true)
            return x.format()
        })
        let d = -1
        if (min.isDST() && !start.isDST()) {
            d = 1
        }
        x.push(moment(end).add(d, 'hours').format())
    } else if (dstTransition < 0) {
        // let d = 0
        // if (min.isDST() && !end.isDST()) {
        //     d = 1
        //     console.log('here')
        // }
        x = Array.from({ length: y.length - Math.floor(3600 / resolution) }).map((_, k) => {
            const x = moment(trace._x[pointStart]).add(k * resolution, 'seconds').add(0, 'hours') // .utc(true)
            if (!x.isDST()) {
                x.add(1, 'hour')
            }
            return x.format()
        })
        x.push(moment(end).format())
        y = y.slice(0, y.length - Math.floor(3600 / resolution))
    } else {
        let d = 0
        if (min.isDST() && end.isDST()) {
            console.log(5)
            // sd = -1
            // ed = -1
            d = -1
        }
        if (min.isDST() && !start.isDST()) {
            console.log(1)
            d = -1
        }
        x = Array.from({ length: y.length }).map((_, k) => {
            const x = moment(trace._x[pointStart]).add(k * resolution, 'seconds').add(d, 'hours') // .utc(true)
            return x.format()
        })
        x.push(moment(end).format())
    }

    const addBitStart = (start.minutes() * 60) % resolution !== 0

    return {
        pointStart,
        pointEnd,
        data: {
            y: [
                ...(addBitStart ? [data[pointStart - 1]] : []), // this point should be more accurately the intersection
                ...y,
                data[pointEnd + 1], // this point should be more accurately the intersection
            ],
            x: [
                ...(addBitStart ? [moment(start).utc(true).format()] : []),
                // ...x.map(x => moment(x).format()),
                ...x,
                // moment(end).format(),
            ],
        },
    }
}

export function generatePeriodData(data = [], { start, end = moment(), resolution }) {
    const diff = difference(end, start)
    const resDiff = Math.round(diff / resolution)

    switch (true) {
        case resDiff < data.length:
            return data.slice(0, resDiff)
        case resDiff > data.length: {
            const newData = [...data]
            while (resDiff > newData.length) {
                newData.push(0)
            }
            return newData
        }
        default:
            return data
    }
}

/**
 * Get trace max data for a given period
 *
 * @param {Object} trace
 * @param {Object} { start, end } range
 * @returns {Object}
 */
export function traceDataForPeriod(trace, { start, end }) {
    const resolution = trace.dx / 1e3
    let data = trace.y
    let pointStart = data.indexOf(data[0])
    let pointEnd = data.lastIndexOf(data.lastItem)

    if (start.isBefore(trace.x0)) {
        start = moment(trace.x0)
    }

    if (!trace.isComparison) {
        const periodSlice = sliceDataForPeriod(trace.y, {
            min: trace.x0,
            start,
            end,
            resolution,
        })

        data = periodSlice.data
        pointStart = periodSlice.pointStart
        pointEnd = periodSlice.pointEnd
    }

    // max data
    const maxValue = max(data)
    const maxIndex = data.findIndex(v => v === maxValue)
    const maxPoint = pointStart + maxIndex

    // min data
    const minValue = min(data)
    const minIndex = data.findIndex(v => v === minValue)
    const minPoint = pointStart + minIndex

    return {
        data,
        pointStart,
        pointEnd,
        max: {
            value: maxValue,
            index: maxIndex,
            point: maxPoint,
            date: pointToDate({ start: trace.x0, point: maxPoint, resolution }),
        },
        min: {
            value: minValue,
            index: minIndex,
            point: minPoint,
            date: pointToDate({ start: trace.x0, point: minPoint, resolution }),
        },
    }
}
