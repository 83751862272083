export default {
    INIT(state) {
        state.initialised = true
    },

    UPDATE(state, payload) {
        for (const p in payload) {
            state[p] = payload[p]
        }
    },

    SET_ABSOLUTE_COMPARISON(state, value) {
        state.absoluteComparison = value
    },
}
