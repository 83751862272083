/* Models */
import { Model } from '@vuex-orm/core'
import { find, orderBy } from 'lodash'
import Site from './Site'
import Pipe from './Pipe'
import Contract from './Contract'
import RagConfiguration from './RagConfiguration'

/* Utils */
export default class GasMeter extends Model {
    static entity = 'gas-meters'
    // static primaryKey = 'meterId'

    static apiConfig = {
        dataKey: 'data',
        url: 'meters',
        params: {
            commodity: ['gas'],
        },
    }

    static fields() {
        return {
            id: this.attr(null),
            siteId: this.attr(null),
            icon: this.attr('$calculator'),
            name: this.attr(''),
            address: this.attr(null),
            installDate: this.attr(null),
            mprn: this.attr(''),
            pipes: this.hasMany(Pipe, 'meterId'),
            editable: this.boolean(true),
        }
    }

    get entity() {
        return GasMeter.entity
    }

    get meterId() {
        return this.id
    }

    get mainIncomer() {
        return find(this.children, { isMainIncomer: true })
    }

    get children() {
        let pipes = this.pipes
        if (pipes.length === 0) {
            pipes = this.getChildren()
        }
        return orderBy(pipes, ['childrenCount', 'totalKwData'], ['desc', 'desc'])
    }

    get fullName() {
        return `${this.parent.name} [${this.mprn}]`
    }

    /**
     * Get site
     */
    get site() {
        return Site.query().whereId(this.siteId).first()
    }

    /**
    * Get meter parent
    */
    get parent() {
        return Site.query().whereId(this.siteId).first()
    }

    /**
    * Get meter siblings
    * Other meters under the same parent
    */
    get siblings() {
        return GasMeter.query()
            .where(record => {
                return record.siteId === this.siteId &&
                            record.meterId !== this.meterId
            })
            .get()
    }

    /**
    * Get relationships
    */
    get relationships() {
        // site
        const site = this.parent
        // pipes
        const pipes = Pipe.query()
            .where('meterId', this.meterId)
            .get()
        return {
            site,
            pipes,
        }
    }

    get contracts() {
        return Contract.query().where('mpan', this.mpanBottom).get()
    }

    get liveContract() {
        return Contract.query().where('mpan', this.mpanBottom).where('is_live', true).first()
    }

    get ragConfiguration() {
        return (async() => {
            const rag = await RagConfiguration.api().get('/rag', {
                params: {
                    gspGroup: this.gspGroup,
                },
                persistBy: 'create',
            })
            return rag.entities.ragconfigurations && rag.entities.ragconfigurations.length ? rag.entities.ragconfigurations[0] : null
        })()
    }

    getName({ showMpan } = {}) {
        if (showMpan) {
            return this.mprn
        }

        return this.name && !this.name.toLowerCase().includes('test')
            ? this.name
            : `Meter ${this.$id}`
    }

    getChildren() {
        return Pipe.query().where('meterId', this.meterId).get()
    }

    /**
     * JSON serializer
     * responsible to define what data will be serialized.
     * @return {Object} json
     */
    toJSON() {
        return {
            ...this.$toJson(),
            entity: this.entity,
            id: this.id,
            children: this.children,
        }
    }
}
