import localforage from 'localforage'
import { setupCache } from 'axios-cache-adapter'
import axios from 'axios'
import { md5 } from '@/utils/tools'
const CancelToken = axios.CancelToken

export default function(ctx) {
    const $axios = ctx.$axios
    let isTokenExpired = false
    $axios.pendingRequests = {}
    const store = localforage.createInstance({
        // List of drivers used
        driver: localforage.LOCALSTORAGE, // this is already by default
        // Prefix all storage keys to prevent conflicts
        name: 'clearVue',
    })

    // Create `axios-cache-adapter` instance
    const cache = setupCache({
        store,
        debug: false,
        exclude: {
            query: false,
            paths: [
                // 'triads',
                'sites',
                'meters',
                'circuits',
                'clamps',
                'presets',
                'customers/[\\d]+',
                'notes',
                'contracts',
                'reports/inspector/pdf',
                'history-clickhouse',
                'electricity-clickhouse', // just in case
                'getToken',
                'auth',
                'login',
                'user',
                'notifications',
                'alerts',
                'generate',
                'jobs',
                'report',
                'net-zero-api',
                'events-api',
                'auth-api/users',
                'targets',
                'assets',
                'settings-api',
                'settings',
                'parameters-api',
                'virtuals',
                'api/rankings',
                // '/readings'
            ],
        },
        maxAge: 15 * 60 * 1000,
        invalidate: (config, request) => {
            if (request.url.includes('logout')) {
                config.store.clear().then(e => {
                    config.store.dropInstance().then(e => {
                        console.log('invalidate: ', e)
                    })
                })
            }
        },
    })

    $axios.defaults.adapter = cache.adapter
    // $axios.interceptors.response.use(
    //     onFulfilled => (response) => {
    //         console.log(response)
    //         return response
    //     },
    //     onRejected => (error) => {
    //         console.log(error)
    //     }
    // )
    // axios.interceptors.response.use(function (response) {
    //     // Any status code that lie within the range of 2xx cause this function to trigger
    //     // Do something with response data
    //     return response;
    // }, function (error) {
    //     // Any status codes that falls outside the range of 2xx cause this function to trigger
    //     // Do something with response error
    //     return Promise.reject(error);
    // });
    /*
     * More config here
     *
        $axios.onRequest(config => {
            console.log('Making request to ' + config.url)
        })

        $axios.onError(error => {
            const code = parseInt(error.response && error.response.status)
            if (code === 400) {
                redirect('/400')
            }
        })
    */

    $axios.onRequest(config => {
        if (isTokenExpired && config.url.includes('login')) {
            isTokenExpired = false
        }
        if (isTokenExpired) {
            if (typeof document !== 'undefined' && !document.querySelector('.v-dialog--persistent')) {
                ctx.store._vm.$confirm('Your session is expired, continue to login again?', {
                    persistent: true,
                }).then(res => {
                    res && location.reload()
                })
            }
            return
        }
        if (config.url.includes('file-data') || config.url.includes('virtual-data') || config.url.includes('entities')) {
            return
        }

        // const hash = md5(config.url)
        const hash = md5([config.url, ...Object.values(config.params || {})].join())

        if ($axios.pendingRequests[hash]) {
            $axios.pendingRequests[hash]()
            delete $axios.pendingRequests[hash]
        }
        config.cancelToken = new CancelToken(c => {
            // An executor function receives a cancel function as a parameter
            $axios.pendingRequests[hash] = c
        })
        ctx?.$api?.tracking?.trackAPI(config.method, config.url.replace(config.baseURL, ''))
    })
    $axios.onError(error => {
        if (axios.isCancel(error)) {
            return false
        }
        const code = parseInt(error.response && error.response.status)
        if (error.config?.url && error.config?.url !== '/auth-api/login' && [401, 403].includes(code)) {
            isTokenExpired = true
            if (typeof document !== 'undefined' && !document.querySelector('.v-dialog--persistent')) {
                ctx.store._vm.$confirm('Your session is expired, continue to login again?', {
                    persistent: true,
                }).then(res => {
                    res && location.reload()
                })
            }
        }
    })
    $axios.onResponse(e => {
        if (e.status === 200) {
            isTokenExpired = false
        }
    })
}
