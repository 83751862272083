/* Models */
import { Model } from '@vuex-orm/core'
import { find, orderBy } from 'lodash'
import Site from './Site'
import WaterPipe from './WaterPipe'
import Contract from './Contract'

/* Utils */
export default class WaterMeter extends Model {
    static entity = 'water-meters'

    static apiConfig = {
        dataKey: 'data',
        url: 'meters',
        params: {
            commodity: ['water'], // 'gas'
        },
    }

    static fields() {
        return {
            id: this.attr(null),
            siteId: this.attr(null),
            icon: this.attr('$calculator'),
            name: this.attr(''),
            address: this.attr(null),
            installDate: this.attr(null),
            meterNumber: this.attr(''),
            pipes: this.hasMany(WaterPipe, 'meterId'),
            editable: this.boolean(true),
        }
    }

    get entity() {
        return WaterMeter.entity
    }

    get meterId() {
        return this.id
    }

    get mainIncomer() {
        return find(this.children, { isMainIncomer: true })
    }

    get children() {
        let pipes = this.pipes
        if (pipes.length === 0) {
            pipes = this.getChildren()
        }
        return orderBy(pipes, ['childrenCount', 'totalKwData'], ['desc', 'desc'])
    }

    get fullName() {
        return `${this.parent.name} [${this.mprn}]`
    }

    /**
     * Get site
     */
    get site() {
        return Site.query().whereId(this.siteId).first()
    }

    /**
     * Get meter parent
     */
    get parent() {
        return Site.query().whereId(this.siteId).first()
    }

    /**
     * Get meter siblings
     * Other meters under the same parent
     */
    get siblings() {
        return WaterMeter.query()
            .where(record => {
                return record.siteId === this.siteId &&
                    record.meterId !== this.meterId
            })
            .get()
    }

    /**
     * Get relationships
     */
    get relationships() {
        // site
        const site = this.parent
        // pipes
        const pipes = WaterPipe.query()
            .where('meterId', this.meterId)
            .get()
        return {
            site,
            pipes,
        }
    }

    get contracts() {
        return Contract.query().where('mpan', this.mpanBottom).get()
    }

    get liveContract() {
        return Contract.query().where('mpan', this.mpanBottom).where('is_live', true).first()
    }

    getName({ showMpan } = {}) {
        if (showMpan) {
            return this.meterNumber
        }

        return this.name && !this.name.toLowerCase().includes('test')
            ? this.name
            : `Meter ${this.$id}`
    }

    getChildren() {
        return WaterPipe.query().where('meterId', this.meterId).get()
    }

    /**
     * JSON serializer
     * responsible to define what data will be serialized.
     * @return {Object} json
     */
    toJSON() {
        return {
            ...this.$toJson(),
            entity: this.entity,
            id: this.id,
            children: this.children,
        }
    }
}
