/* Models */
import { Model } from '@vuex-orm/core'
import { get, intersection, orderBy } from 'lodash'
import { n } from '~/plugins/filters'

export default class Unit extends Model {
    #children
    #parent

    static entity = 'units'
    static primaryKey = 'id'
    static COMMODITY_PARAMS = {
        all: ['E', 'U_CO2E', 'C', 'S', 'PF', 'GVOL'],
        power: ['E', 'U_CO2E', 'C', 'S', 'PF', 'Q'],
        gas: ['E', 'U_CO2E', 'C', 'GVOL', 'GCAL'],
        water: ['U_WVOL', 'C_WVOL', 'C', 'WVOL', 'WCAL'],
    }

    static CUMULATIVE = 'cumulative'
    static INSTANT = 'instant'
    static POWER = 'power'
    static GAS = 'gas'
    static water = 'water'

    /**
     * retro-compatibility with old hard-coded parameters
     */
    static dataToMerge = currency => [
        {
            id: 'kw',
            param: 'P',
            // symbol: 'kW',
            // name: 'Power',
            allowPreview: true,
            instant: true,
            // _multiplier: 1,
            symbols: {
                base: ['W', 1],
                kilo: ['kW', 1e3],
                mega: ['MW', 1e6],
                giga: ['GW', 1e9],
                tera: ['TW', 1e12],
            },
            dataAggregation: 'max',
            aggregates: ['avg', 'max', 'min'],
            scope: 'electricity',
        },
        {
            id: 'kwh',
            param: 'E',
            // symbol: 'kWh',
            // name: 'Energy',
            allowPreview: true,
            instant: false,
            // _multiplier: 1,
            symbols: {
                base: ['Wh', 1],
                kilo: ['kWh', 1e3],
                mega: ['MWh', 1e6],
                giga: ['GWh', 1e9],
                tera: ['TWh', 1e12],
            },
            dataAggregation: 'sum',
            aggregates: ['avg', 'max', 'min', 'sum'],
            scope: 'electricity',
        },
        {
            id: 'kva',
            param: 'S',
            // symbol: 'kVA',
            // name: 'Apparent Power',
            allowPreview: true,
            instant: true,
            // _multiplier: 1,
            symbols: {
                base: ['VA', 1],
                kilo: ['kVA', 1e3],
                // mega: ['MVA', 1e6],
                // giga: ['GVA', 1e9],
                // tera: ['TVA', 1e12],
            },
            dataAggregation: 'max',
            aggregates: ['avg', 'max', 'min'],
            scope: 'electricity',
        },
        {
            id: 'kvah',
            param: 'AE',
            // symbol: 'kVAh',
            // name: 'Apparent Energy',
            allowPreview: true,
            instant: false,
            // _multiplier: 1,
            symbols: {
                base: ['VAh', 1],
                kilo: ['kVAh', 1e3],
                mega: ['MVAh', 1e6],
                giga: ['GVAh', 1e9],
                tera: ['TVAh', 1e12],
            },
            dataAggregation: 'sum',
            aggregates: ['avg', 'max', 'min', 'sum'],
            scope: 'electricity',
        },
        {
            id: 'pf',
            param: 'PF',
            // symbol: 'PF',
            // name: 'Power Factor',
            allowPreview: true,
            instant: true,
            dataAggregation: 'avg',
            aggregates: ['avg', 'max', 'min'],
            scope: 'electricity',
            disableMultipliers: true,
        },
        {
            id: 'pf1',
            param: 'PF1',
            scope: 'electricity',
            disableMultipliers: true,
        },
        {
            id: 'pf2',
            param: 'PF2',
            scope: 'electricity',
            disableMultipliers: true,
        },
        {
            id: 'pf3',
            param: 'PF3',
            scope: 'electricity',
            disableMultipliers: true,
        },
        {
            id: 'i',
            param: 'I',
            scope: 'electricity',
            staticMultiplier: 1,
        },
        {
            id: 'i1',
            param: 'I1',
            scope: 'electricity',
            staticMultiplier: 1,
        },
        {
            id: 'i2',
            param: 'I2',
            scope: 'electricity',
            staticMultiplier: 1,
        },
        {
            id: 'i3',
            param: 'I3',
            scope: 'electricity',
            staticMultiplier: 1,
        },
        {
            id: 'kvar',
            param: 'Q',
            // symbol: 'kVAr',
            // name: 'Reactive Power',
            allowPreview: true,
            instant: true,
            // _multiplier: 1,
            symbols: {
                base: ['VAr', 1],
                kilo: ['kVAr', 1e3],
                mega: ['MVAr', 1e6],
                giga: ['GVAr', 1e9],
                tera: ['TVAr', 1e12],
            },
            dataAggregation: 'max',
            aggregates: ['avg', 'max', 'min'],
            scope: 'electricity',
        },
        {
            id: 'kvarh',
            param: 'RE',
            // symbol: 'kVArh',
            // name: 'Reactive Energy',
            allowPreview: true,
            instant: false,
            // _multiplier: 1,
            symbols: {
                base: ['VArh', 1],
                kilo: ['kVArh', 1e3],
                mega: ['MVArh', 1e6],
                giga: ['GVArh', 1e9],
                tera: ['TVArh', 1e12],
            },
            dataAggregation: 'sum',
            aggregates: ['avg', 'max', 'min', 'sum'],
            scope: 'electricity',
        },
        {
            id: 'co2e',
            param: 'U_CO2E',
            // symbol: 'kg',
            // name: 'CO₂e',
            allowPreview: true,
            instant: false,
            symbols: {
                base: ['kg', 1],
                kilo: ['t', 1e3],
            },
            dataAggregation: 'sum',
            aggregates: ['avg', 'max', 'min', 'sum'],
            scope: 'electricity',
        },
        {
            id: 'cost',
            param: 'C',
            // symbol: currency.symbol,
            // name: 'Cost',
            allowPreview: false,
            instant: false,
            symbols: {
                base: [currency.symbol, 1],
                kilo: [`${currency.symbol}k`, 1e3],
                mega: [`${currency.symbol}M`, 1e6],
            },
            disableMultipliers: true,
            dataAggregation: 'sum',
            aggregates: ['avg', 'max', 'min', 'sum'],
            scope: 'electricity',
            unitBeforeValue: true,
        },
        {
            id: 'loadFactor',
            // symbol: 'LF',
            name: 'Load Factor',
            dataAggregation: 'avg',
            scope: 'electricity',
            hideFromTable: true,
        },
        {
            id: 'triad',
            // symbol: 'T',
            name: 'Triads',
            symbols: {
                base: [currency.symbol, 1],
                kilo: [`${currency.symbol}k`, 1e3],
                mega: [`${currency.symbol}M`, 1e6],
            },
            dataAggregation: 'sum',
            scope: 'electricity',
        },
        // Gas units
        {
            id: 'gvol',
            param: 'GVOL',
            // symbol: 'm³',
            // name: 'Volume',
            allowPreview: true,
            instant: false,
            symbols: {
                base: ['m³', 1],
                deca: ['dam³', 1e3],
                hecto: ['hm³', 1e6],
                kilo: ['km³', 1e9],
            },
            dataAggregation: 'sum',
            aggregates: ['avg', 'max', 'min', 'sum'],
            scope: 'gas',
            disableMultipliers: true,
        },
        {
            id: 'ugene',
            param: 'U_GENE',
            // symbol: 'kWh',
            // name: 'Energy',
            allowPreview: true,
            instant: false,
            // _multiplier: 1,
            symbols: {
                base: ['Wh', 1],
                kilo: ['kWh', 1e3],
                mega: ['MWh', 1e6],
                giga: ['GWh', 1e9],
                tera: ['TWh', 1e12],
            },
            dataAggregation: 'sum',
            aggregates: ['avg', 'max', 'min', 'sum'],
            scope: 'gas',
        },
        {
            id: 'cgene',
            param: 'C_GENE',
            // symbol: currency.symbol,
            // name: 'Cost',
            allowPreview: true,
            instant: false,
            dataAggregation: 'sum',
            aggregates: ['avg', 'max', 'min', 'sum'],
            scope: 'gas',
            disableMultipliers: true,
            unitBeforeValue: true,
        },
        {
            id: 'gcal',
            param: 'GCAL',
            // symbol: 'MJ/m3',
            // name: 'Calorific Value',
            allowPreview: true,
            instant: true,
            dataAggregation: 'avg',
            aggregates: ['avg', 'max', 'min'],
            scope: 'gas',
            disableMultipliers: true,
        },
        {
            id: 'uco2e',
            param: 'U_CO2E',
            // symbol: 'kg',
            // name: 'CO₂e',
            allowPreview: true,
            instant: false,
            symbols: {
                base: ['g', 1],
                kilo: ['kg', 1e3],
                mega: ['t', 1e6],
            },
            dataAggregation: 'sum',
            aggregates: ['avg', 'max', 'min', 'sum'],
            scope: 'gas',
        },
        // Water units
        {
            id: 'wvol',
            param: 'WVOL',
            // symbol: 'm³',
            // name: 'Volume',
            allowPreview: true,
            instant: false,
            symbols: {
                base: ['m³', 1],
                deca: ['dam³', 1e3],
                hecto: ['hm³', 1e6],
                kilo: ['km³', 1e9],
            },
            dataAggregation: 'sum',
            aggregates: ['avg', 'max', 'min', 'sum'],
            scope: 'water',
            disableMultipliers: true,
        },
        {
            id: 'uwvol',
            param: 'U_WVOL',
            // symbol: 'kWh',
            // name: 'Energy',
            allowPreview: true,
            instant: false,
            // _multiplier: 1,
            symbols: {
                base: ['m³', 1],
                // deca: ['dam³', 1e3],
                // hecto: ['hm³', 1e6],
                // kilo: ['km³', 1e9],
                // base: ['Wh', 1],
                // kilo: ['kWh', 1e3],
                // mega: ['MWh', 1e6],
                // giga: ['GWh', 1e9],
                // tera: ['TWh', 1e12],
            },
            dataAggregation: 'sum',
            aggregates: ['avg', 'max', 'min', 'sum'],
            scope: 'water',
            disableMultipliers: true,
        },
        {
            id: 'cwvol',
            param: 'C_WVOL',
            // symbol: currency.symbol,
            // name: 'Cost',
            allowPreview: true,
            instant: false,
            dataAggregation: 'sum',
            aggregates: ['avg', 'max', 'min', 'sum'],
            scope: 'water',
            disableMultipliers: true,
            unitBeforeValue: true,
        },
        {
            id: 'wcal',
            param: 'WCAL',
            // symbol: 'MJ/m3',
            // name: 'Calorific Value',
            allowPreview: true,
            instant: true,
            dataAggregation: 'avg',
            aggregates: ['avg', 'max', 'min'],
            scope: 'water',
        },
        {
            id: 'csew',
            param: 'C_SEW',
            dataAggregation: 'sum',
            scope: 'water',
            allowPreview: true,
            disableMultipliers: true,
            unitBeforeValue: true,
            aggregates: ['avg', 'max', 'min', 'sum'],
        },
        {
            id: 'usew',
            param: 'U_SEW',
            scope: 'water',
            allowPreview: true,
            instant: false,
            symbols: {
                base: ['m³', 1],
            },
            dataAggregation: 'sum',
            aggregates: ['avg', 'max', 'min', 'sum'],
            disableMultipliers: true,
        },
        // {
        //     id: 'Wco2e',
        //     param: 'U_CO2E',
        //     // symbol: 'kg',
        //     // name: 'CO₂e',
        //     allowPreview: true,
        //     instant: false,
        //     symbols: {
        //         base: ['g', 1],
        //         kilo: ['kg', 1e3],
        //         mega: ['t', 1e6],
        //     },
        //     dataAggregation: 'sum',
        //     aggregates: ['avg', 'max', 'min', 'sum'],
        //     scope: 'water',
        // },
    ]

    static apiConfig = {
        url: 'parameters',
        dataKey: 'data',
    }

    static orderMap = [
        'E', 'E1', 'E2', 'E3', 'C', 'P', 'P1', 'P2', 'P3', 'Q', 'Q1', 'Q2', 'Q3', 'S', 'S1', 'S2', 'S3', 'PF', 'PF1', 'PF2', 'PF3', 'V', 'V1', 'V2', 'V3', 'I', 'I1', 'I2', 'I3', 'U', 'U12', 'U23', 'U31', 'RE', 'RE1', 'RE2', 'RE3', 'EX', 'EX1', 'EX2', 'EX3', 'REX', 'REX1', 'REX2', 'REX3', 'U_CO2E', 'U_CO2', 'U_CH4', 'U_N2O', 'A12', 'A13', 'F', 'AE', 'AE1', 'AE2', 'AE3',
    ]

    static fields() {
        return {
            id: this.attr(''),
            param: this.attr(''),
            // symbol: this.attr(''),
            name: this.attr(null),
            allowPreview: this.boolean(false),
            instant: this.boolean(false),
            _multiplier: this.attr(1),
            symbols: this.attr(null),
            dataAggregation: this.attr(''),
            aggregates: this.attr([]),
            scope: this.attr(null),
            hideFromTable: this.boolean(false),
            unitBeforeValue: this.boolean(false),
            disableMultipliers: this.boolean(false),
            // children: this.attr([]),

            // from API:
            key: this.attr(null),
            unit: this.attr(null),
            allowedSampleTypes: this.attr([]),
            description: this.attr(''),
            type: this.attr(null),
            commodity: this.attr(null),
            parentKey: this.attr(null),
            tags: this.attr([]),
            forcedMultiplier: this.attr(null),
            staticMultiplier: this.attr(null),
        }
    }

    /**
     * necessary hack for legacy ids, in order to remove this, first need to replace all old ids with the new ones
     * ie: kwh => E, kw => P, etc
     * good luck
     * @param model
     */
    static beforeCreate(model) {
        const disabledDynamicMultiplier = !(this.store().state.settings.general.dynamicUnitScaling === undefined || this.store().state.settings.general.dynamicUnitScaling === true)
        const currency = this.store().getters.currency
        const dataToMerge = Unit.dataToMerge(currency).find(({ param }) => param === model.key)
        if (dataToMerge) {
            for (const [key, value] of Object.entries(dataToMerge)) {
                model[key] = value
            }
        } else {
            model.id = model.key
            model.param = model.key
            model.name = model.description
            // model.symbol = model.unit
        }
        if (!model.disableMultipliers && disabledDynamicMultiplier) {
            model.disableMultipliers = true
            model.forcedMultiplier = model.staticMultiplier ?? 1e3
        }
        model.unit = model.unit
            .replace('{CURRENCY}', this.store().getters.currency.symbol || '£')
            .replace('{CURRENCY_MINOR}', this.store().getters.currency.symbol === '£' ? 'p' : 'c')
    }

    // static async afterCreate(model){
    //     // console.log(model, Unit.all().length)
    //     // model.children = Unit.query().where('parentKey', model.key).get()
    //     const r = await Unit.update({
    //         where: model.key,
    //         data: {
    //             ...model,
    //             children: Unit.query().where('parentKey', model.key).get()
    //         }
    //     })
    //     console.log(r)
    // }

    /**
     * necessary hack to force vuex orm to query by id and not by $id
     * @param id
     * @returns {Instance<Unit>}
     */
    static find(id) {
        switch (id) {
            case 'triad':
            case 'loadFactor':
                id = 'P'
                break
        }
        return Unit.all().find(e => e.id === id || e.key === id)
    }

    static findByParam(param) {
        return this.query().where('param', param).first()
    }

    static params(exclude = []) {
        return this.all()
            .filter(u => Boolean(u.param) && !exclude.includes(u.param))
            .map(u => u.param)
    }

    static idToParam(unitId) {
        return this.find(unitId)?.param
    }

    static idToName(unitId) {
        return this.find(String(unitId).toLowerCase())?.name
    }

    static paramToId(param) {
        return this.findByParam(param)?.id
    }

    static paramToName(param) {
        return this.findByParam(param)?.name
    }

    static commodityParams(commodity = 'all') {
        return this.COMMODITY_PARAMS[commodity]
    }

    static forCommodity(commodity = 'all') {
        return this.COMMODITY_PARAMS[commodity].map(param => this.findByParam(param))
    }

    /**
     * Normalize unit value and symbol
     *
     * > 99999 MWh
     * > 99999999 GWh
     *
     * @returns {Object}
     */
    static normalize(value, unitId, precision = 2, fromSymbol = '', debug = false) {
        if (!value || !unitId) return { value }
        const unit = this.find(unitId)
        let symbol = unit.symbol

        if (unit.symbols) {
            let _multiplier = 1
            const symbols = Object.values(unit.symbols)
            if (fromSymbol) {
                const index = symbols.findIndex(item => item[0] === fromSymbol)
                if (index >= 0) {
                    _multiplier = Math.pow(1e3, index)
                    symbols.splice(0, index)
                }
            }
            symbol = symbols[0][0]
            for (const [s, divider] of symbols.reverse()) {
                if (debug) {
                    console.table({
                        unitId,
                        s,
                        fromSymbol,
                        value,
                        divider,
                        _multiplier,
                    })
                    console.log('symbols: ', symbols)
                }
                if (value > divider / _multiplier) {
                    value /= divider / _multiplier
                    symbol = s
                    break
                }
            }
        }

        return {
            value: n(value, precision),
            symbol,
        }
    }

    valueFromAggregates(aggregates) {
        let aggregate = {}
        switch (true) {
            case ['PF', 'GCAL'].includes(this.param):
                aggregate = aggregates?.avg
                break
            case ['S', 'Q'].includes(this.param):
                aggregate = aggregates?.max
                break
            case ['C', 'U_CO2E', 'E', 'RE', 'U_GENE', 'GVOL'].includes(this.param):
                aggregate = aggregates?.sum
                break
        }

        return get(aggregate, `${this.param}.value`, null)
    }

    static allParameters(commodity = null, parentsOnly = true, tags = []) {
        let q = Unit.query()
        if (parentsOnly) {
            q = q.where('parentKey', null)
        }
        if (commodity) {
            q = q.where('commodity', e => e.includes(commodity))
        }
        if (tags?.length) {
            q = q.where('tags', e => {
                return intersection(e, tags).length
            })
        }
        return orderBy(q.get(), 'order')
    }

    static aggregatesPayload(units = []) {
        return units.reduce((acc, value) => {
            value.aggregates.forEach(a => {
                if (!acc[a]) {
                    acc[a] = []
                }
                acc[a].push(value.param)
            })
            return acc
        }, {})
    }

    get label() {
        return this.name ?? this.description
    }

    get isInstant() {
        return this.type === Unit.INSTANT
    }

    get isCumulative() {
        return this.type === Unit.CUMULATIVE
    }

    get children() {
        this.#children = this.#children || Unit.query().where('parentKey', this.key).get()
        return this.#children
    }

    get parent() {
        if (!this.parentKey) return
        this.#parent = this.#parent || Unit.query().where('key', this.parentKey).first()
        return this.#parent
    }

    get multiplier() {
        return this._multiplier || this.parent?.multiplier || 1
    }

    get units() {
        return this.symbols || this.parent?.symbols
    }

    get isCost() {
        return this.tags.includes('cost')
    }

    get symbol() {
        return this.unit
    }

    get order() {
        const o = Unit.orderMap.indexOf(this.key)
        return o >= 0 ? o : 9999
    }
}
