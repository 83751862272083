import moment from 'moment-timezone'

/**
 * Using this composable as a single starting point for moment timezone
 */

moment.fn.round = function(precision, key, direction) {
    direction = direction || 'round'
    const _this = this // cache of this
    const methods = {
        hours: { name: 'Hours', maxValue: 24 },
        minutes: { name: 'Minutes', maxValue: 60 },
        seconds: { name: 'Seconds', maxValue: 60 },
        milliseconds: { name: 'Milliseconds', maxValue: 1000 },
    }
    const keys = {
        mm: methods.milliseconds.name,
        milliseconds: methods.milliseconds.name,
        Milliseconds: methods.milliseconds.name,
        s: methods.seconds.name,
        seconds: methods.seconds.name,
        Seconds: methods.seconds.name,
        m: methods.minutes.name,
        minutes: methods.minutes.name,
        Minutes: methods.minutes.name,
        H: methods.hours.name,
        h: methods.hours.name,
        hours: methods.hours.name,
        Hours: methods.hours.name,
    }
    let value = 0
    let rounded = false
    let subRatio = 1
    let maxValue

    // make sure key is plural
    if (key.length > 1 && key !== 'mm' && key.slice(-1) !== 's') {
        key += 's'
    }
    key = keys[key].toLowerCase()

    // control
    if (!methods[key]) {
        throw new Error('The value to round is not valid. Possibles ["hours", "minutes", "seconds", "milliseconds"]')
    }

    const get = 'get' + methods[key].name
    const set = 'set' + methods[key].name

    for (const k in methods) {
        if (k === key) {
            value = _this._d[get]()
            maxValue = methods[k].maxValue
            rounded = true
        } else if (rounded) {
            subRatio *= methods[k].maxValue
            value += _this._d['get' + methods[k].name]() / subRatio
            _this._d['set' + methods[k].name](0)
        }
    }

    value = Math[direction](value / precision) * precision
    value = Math.min(value, maxValue)
    _this._d[set](value)

    return _this
}

moment.fn.addResolution = function(resolution, count = 1) {
    const start = this.clone()
    if (isNaN(resolution)) {
        if (resolution === 'customWeek') {
            this.add(count, 'week')
        } else {
            this.add(count, resolution)
        }
    } else {
        this.add(resolution * count, 's')
        // add / subtract dst difference
        if (start.isDST() && !this.isDST()) {
            this.add(60, 'm')
        } else if (!start.isDST() && this.isDST()) {
            this.subtract(60, 'm')
        }
    }

    return this
}

moment.fn.subtractResolution = function(resolution, count = 1) {
    if (isNaN(resolution)) {
        if (resolution === 'customWeek') {
            this.subtract(count, 'week')
        } else {
            this.subtract(count, resolution)
        }
    } else {
        this.subtract(resolution * count, 's')
    }
    return this
}

/**
 * Round to resolution
 * @param {Number|String} resolution
* @param {String} direction - default 'round', can also be 'floor' or 'ceil'
 * @returns {Object} moment
 */
moment.fn.roundToResolution = function(resolution, direction = 'round') {
    if (isNaN(resolution)) {
        this.round(1, resolution, direction)
    } else {
        this.round(resolution / 60, 'm', direction)
    }
    return this
}

moment.fn.isEndOfDay = function() {
    return this.format('HH:mm:ss') === '23:59:59'
}

moment.fn.isStartOfDay = function() {
    return this.format('HH:mm:ss') === '00:00:00'
}

/**
 * Sets the current moment to the start day of the moment's current week
 * @param {Number} startDay
 * @returns {Object} moment
 */
moment.fn.startOfCustomWeek = function(startDay = 1) {
    const currentDay = this.day()
    if (currentDay === startDay) {
        return this.startOf('day')
    }
    let daysToSubtract = currentDay - startDay
    if (daysToSubtract < 0) {
        daysToSubtract += 7
    }
    this.subtract(daysToSubtract, 'days')
    this.startOf('day')
    return this
}

export function setTimezone(timezone = 'Europe/London') {
    moment.tz.setDefault(timezone)
    moment.TIMEZONE = timezone
    // the offset between UTC and the user configured timezone (Europe/London)
    moment.TZ_UTC_OFFSET = moment().tz(timezone).utcOffset()
    moment.UTC_OFFSET = moment().utcOffset()
}

export function setLocale(locale) {
    moment.locale(locale)
}

// set default timezone
setTimezone()

export default moment
