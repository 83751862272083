import pDebounce from 'p-debounce'
import { FROM_ROOT } from '~/utils/state'
import moment from '~/composables/useMoment'

export default {
    async init({ commit, dispatch, rootState, rootGetters }) {
        // select sites
        let siteIds = []
        if (rootState.selectedSiteIds.length > 0) {
            siteIds = rootState.selectedSiteIds
        } else {
            siteIds = rootGetters['entities/carbon-sources/sitesTree'].map(({ id }) => id)
        }
        await dispatch('entities/carbon-sources/toggleSiteSelection', siteIds, FROM_ROOT)

        await dispatch('fetchAssetCounters')
        dispatch('fetchEmissions')
        commit('INIT')
    },

    fetchAssetCounters: pDebounce(async function({ dispatch }) {
        // eslint-disable-next-line prefer-const
        let { data, from, to } = await this.$api.netZero.loadCounters()
        // init period
        const initPeriod = {
            start: this.$moment().subtract(1, 'year'),
            end: this.$moment(),
            min: from,
            max: to,
        }
        await dispatch('period/init', initPeriod, FROM_ROOT)
        const sources = this.$db().model('carbon-sources').all()
        const sourceIds = sources.map(e => e.id)
        data = data.filter(e => sourceIds.includes(e.assetId))
        await this.$db().model('carbon-sources').insert({
            data: data.map(({ records, assetId }) => {
                return {
                    id: assetId,
                    ...sources.find(({ id }) => id === assetId),
                    records,
                }
            }),
        })
    }, 100),

    fetchEmissions: pDebounce(function({ commit, getters, rootGetters }) {
        const nodeIds = this.$db()
            .model('carbon-sources')
            .query()
            .where('parentId', value => value && value.includes('c-scope-'))
            .get()
            .map(v => v.id)
        const payload = {
            emissions: ['co2e'],
            nodeIds,
            resolution: getters.dynamicResolution,
            daterange: rootGetters['period/rangeToISO'],
            reportingYearStartMonth: moment(rootGetters['period/rangeToISO'][0]).month() + 1,
            reportingYearStartDay: moment(rootGetters['period/rangeToISO'][0]).date(),
            timezone: rootGetters['period/timezone'],
        }
        try {
            this.$api.netZero
                .fetchEmissions({
                    ...payload,
                    splitBy: ['assetId', 'timestamp'],
                })
                .then(data => commit('SET_EMISSIONS', { assets: data }))
            this.$api.netZero
                .fetchEmissions({
                    ...payload,
                    splitBy: ['nodeId', 'timestamp'],
                })
                .then(data => commit('SET_EMISSIONS', { nodes: data }))
        } catch (e) {
            console.warn('Error while fetching site readings. ', e.message)
            commit('SET_EMISSIONS', [])
        }
    }, 100),
}
