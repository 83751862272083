/* eslint-disable no-mixed-operators */
/* eslint-disable no-unreachable-loop */
import { isEqual, unionBy, remove, intersectionBy, sortBy, uniq, compact } from 'lodash'
import { consolidateEntities } from '~/utils/entities'
import Unit from '~/orm/models/Unit'
import ParametersPreset from '~/orm/models/ParametersPreset'
import moment from '~/composables/useMoment'

export default {
    unit(state) {
        return state.selectedUnit
    },

    isMinPF(state) {
        return Boolean(
            state.selectedPoint &&
            state.selectedUnit === 'pf' &&
            state.selectedPoint.scope === 'min' &&
            state.selectedPoint.datasetId.slice(0, 2) === 'pf',
        )
    },

    tabId(state) {
        return state.tabIndex === '0' ? 'all' : 'virtual'
    },

    activeCircuits(state, getters, rootState, rootGetters) {
        return rootGetters['entities-manager/activeCircuits'](getters.tabId)
    },

    selectedCircuits(state, getters, rootState, rootGetters) {
        return rootGetters['entities-manager/selectedCircuits'](getters.tabId)
    },

    selectedCircuitsIds(state, getters) {
        return getters.selectedCircuits.map(c => c.id)
    },

    /**
     * Real selected circuits
     * > in the case of virtual circuits
     *   we want to return their children instead
     *
     * @param {Object} state
     * @param {Object} getters
     */
    realSelectedCircuits(state, getters, rootState, rootGetters) {
        let selectedCircuits = [...getters.selectedCircuits]
        const virtualCircuits = remove(selectedCircuits, c => c.$id.includes('V'))
        for (const vc of virtualCircuits) {
            const presetId = parseInt(vc.$id.replace('V', ''))
            const preset = rootGetters['entities/presets/find'](presetId)
            selectedCircuits = unionBy(selectedCircuits, preset.circuitsChildren.filter(c => !c.$id.includes('V')), '$id')
        }
        return selectedCircuits
    },

    /**
     * Filter selected circuits
     * so that we don't include both parents and children
     * logic as per CLAL-385 ticket requirements
     *
     * @returns {Array} circuits
     */
    consolidatedCircuits(state, getters, rootState) {
        const { period } = rootState
        const circuits = getters.realSelectedCircuits
        return consolidateEntities(circuits, period)
    },

    /**
     * Filtered circuits IDs
     *
     * @returns {Array} circuitIds
     */
    consolidatedCircuitsIds(state, getters) {
        return getters.consolidatedCircuits.map(c => c.id)
    },

    selectedSiteIds(_state, getters) {
        return compact([...new Set(getters.realSelectedCircuits.map(c => c.siteId))])
    },

    treeViewItems(state) {
        return state.treeItems.all
    },

    openTreeViewItems(state, getters) {
        const items = []
        function recursivePush(item, depth) {
            if (depth >= 20 || !item.euid) {
                return
            }
            items.push(item.euid)
            if (item.children && item.children.length > 0) {
                recursivePush(item.children[0], depth + 1)
            }
        }
        recursivePush(getters.treeViewItems[0] || {}, 0)
        return items
    },

    initialTreeViewItems(_state, getters) {
        const items = []

        let hasMain = false
        let hasSite = false
        for (const customer of getters.treeViewItems) {
            for (const site of customer.children) {
                for (const meter of site.children) {
                    if (meter.activatable) {
                        hasMain = true
                        items.push(meter.euid)
                        break
                    } else if (meter.children.length > 0) {
                        for (const circuit of meter.children) {
                            if (circuit.activatable) {
                                items.push(circuit.euid)
                                break
                            } else if (circuit.children.length > 0) {
                                if (!circuit.activatable) {
                                    items.push(circuit.euid, meter.euid, site.euid, customer.euid)
                                    items.push(...circuit.children.map(({ euid }) => euid))
                                } else {
                                    items.push(circuit.euid)
                                    break
                                }
                            }
                        }
                        break
                    }
                }
                if (hasMain && site.children.length === 1) {
                    hasSite = true
                    items.push(site.euid)
                }
                break
            }
            if (hasSite && customer.children.length === 1) items.push(customer.euid)
            break
        }

        return uniq(items)
    },

    sitesTreeviewItems: (_state, getters) => (siteIds = []) => {
        const customer = getters.treeViewItems[0]
        const allSites = customer.children
        const sites = allSites.filter(s => siteIds.includes(s.id))
        const euids = sites.map(site => {
            return [
                site.euid,
                ...site.children.map(c => c.euid),
            ]
        }).flat()
        if (sites.length === allSites.length) {
            euids.push(customer.euid)
        }
        return euids
    },

    virtualTreeViewItems(state) {
        return state.treeItems.virtual
    },

    openVirtualTreeViewItems(state, getters) {
        const items = []
        for (const customer of getters.virtualTreeViewItems) {
            items.push(customer.euid)
            // for (let preset of customer.children) {
            //     items.push(preset.euid)
            // }
        }
        return items
    },

    homeTabHasChanges(state, getters, rootState) {
        return rootState['entities-manager'].activeEntities.all.length ||
            !isEqual(
                [...rootState['entities-manager'].selectedEntities.all].sort(),
                [...getters.initialTreeViewItems].sort(),
            )
    },

    virtualTabHasChanges(state, getters, rootState) {
        return rootState['entities-manager'].activeEntities.virtual.length ||
            rootState['entities-manager'].selectedEntities.virtual.length
    },

    hasChanges(state, getters) {
        return state.tabIndex === '0' && getters.homeTabHasChanges ||
            state.tabIndex === '1' && getters.virtualTabHasChanges
    },

    isCurrentResolutionStacking(state, getters, rootState, rootGetters) {
        return state.selectedUnit !== 'pf' && rootGetters['period/isCurrentResolutionStacking']
    },

    circuitWithOldestStartTime(state, getters, rootState, rootGetters) {
        const circuits = []
        getters.selectedCircuits.forEach(circuit => {
            if (circuit.virtual) {
                circuits.push(...circuit.siblings)
            } else {
                circuits.push(circuit)
            }
        })
        return sortBy(intersectionBy(circuits, rootGetters['entities/circuits/startTimeSortedList'], 'id'), ['startTime'])[0]
    },

    nextInLine: (state, getters) => entity => {
        const children = entity[entity.isMainIncomer ? 'siblings' : 'children'].map(child => {
            return child.hasData ? child : getters.nextInLine(child)
        })
        return children.flat()
    },

    selectedParameters(state, getters) {
        return state.selectedParameterKeys.filter(key => {
            if (getters.preset?.keysOnly) {
                return getters.preset.keysOnly.includes(key)
            } else {
                return false
            }
        }).map(key => Unit.find(key))
    },

    preset(state) {
        return ParametersPreset.find(state.selectedParametersPreset)
    },

    availableSelectedUnit(state, getters) {
        if (getters.preset?.keysOnly.includes(state.selectedUnit)) {
            return state.selectedUnit
        }
    },

    comparisonStartEndDuration(state, getters, rootState, rootGetters) {
        if (state.comparison?.shift && state.comparison?.start !== state.comparison?.end) {
            const timeFormat = 'MMM Do HH:mm, Y'
            const start = moment(state.comparison.start).format(timeFormat)
            const end = moment(state.comparison.end).subtract(1, 's').format(timeFormat)
            return {
                start,
                end,
                duration: rootGetters['period/humanizedDuration'],
            }
        }
    },

    supported(_state, _getters, _rootState, rootGetters) {
        return rootGetters['entities/meters/all']().length > 0
    },
}
