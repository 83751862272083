// Utils
import Queue from 'promise-queue'
import { debounce, isNumber, isString, keys, sum, values } from 'lodash'
import { float } from '~/utils/math'
import { utcDate, DST_START_TIMES, DST_END_TIMES } from '~/utils/date'
import { COMMODITY_TYPES, idKey } from '~/utils/entities'
import { generateCacheKey } from '~/utils/cache'
import moment from '~/composables/useMoment'

// ORM
import Unit from '~/orm/models/Unit'
import StorageService from '~/services/Storage'
import LocalStorageService from '~/services/LocalStorage'
import { VALID_RESOLUTIONS } from '~/utils/resolution'

const MIN_MOMENT = moment().subtract(10, 'year').startOf('h')

/**
 * Cache service
 *
 * > Loads data on demand for each entity, stores it for future use
 *   and auto updates it every resolution interval
 *
 */
export default class CacheService {
    _concurrentDemandLoading = 0
    _loading = false
    _updating = false
    _initialised = false
    _loadedItems = {}
    _queue = {}
    _loadingAttempts = {}

    constructor(ctx) {
        this.$api = ctx.$api
        this.$auth = ctx.$auth
        this.$store = ctx.store
        this.$eventBus = ctx.$eventBus
        this.$queue = new Queue(5, Infinity)
        this.$localStorage = new LocalStorageService()
        // set debouncer
        this.sendLoadedItemsToStore = debounce(this.sendLoadedItemsToStore, 500, { maxWait: 2500 })
    }

    get initialised() {
        return this._initialised
    }

    get period() {
        return this.$store.state.period
    }

    get resolution() {
        return this.period.resolution
    }

    get loading() {
        return this._loading
    }

    set loading(value) {
        this._loading = value
    }

    get loadedItems() {
        return this._loadedItems
    }

    set loadedItems(value) {
        this._loadedItems = value
    }

    get updating() {
        return this._updating
    }

    set updating(value) {
        this._updating = value
    }

    get concurrentDemandLoading() {
        return this._concurrentDemandLoading
    }

    set concurrentDemandLoading(v) {
        this.$eventBus.$emit('cache-concurrent-demand-loading', v)
        this._concurrentDemandLoading = v
    }

    /**
     * Map data to the format we want
     * when we'll be happy with this format we can require this change on API
     *
     * @param {String} unit
     * @param {Array} collection
     *
     * @param resolution
     * @returns {Array} collection
     */
    _prepareDataForSaving(unit, collection = [], resolution = null, scopes) {
        if (!scopes) {
            console.warn('missing commodity')
        }
        resolution = resolution || this.resolution
        const prefix = generateCacheKey([unit, resolution, scopes])

        return collection.map(item => {
            let { id, entity } = item
            if (!id) id = item.circuitId || item.pipeId || item.siteId
            if (!entity) entity = item.pipeId ? 'pipes' : item.siteId ? 'sites' : 'circuits'

            item.uid = generateCacheKey([prefix, entity, id])
            item.data = item[unit]
            item.startTime = +utcDate(item.startTime, 'x')
            item.endTime = +utcDate(item.endTime, 'x')
            item.resolution = resolution
            delete item[unit]
            delete item.circuitId

            return item
        })
    }

    isItemLoaded(key) {
        return Object.prototype.hasOwnProperty.call(this.loadedItems, key)
    }

    loadItem(key, item) {
        this.loadedItems[key] = item
        this.sendLoadedItemsToStore()
    }

    unloadItem(key) {
        this.loadedItems[key] = undefined
        this.$storage.remove(key)
        this.sendLoadedItemsToStore()
    }

    sendLoadedItemsToStore() {
        const keys = Object.keys(this.loadedItems)
        const forCircuits = keys.filter(k => k.includes('_circuits_')).map(k => k.replace('_circuits', ''))
        const forPipes = keys.filter(k => k.includes('_pipes_')).map(k => k.replace('_pipes', ''))
        if (forCircuits.length > 0) {
            this.$store.commit('entities/circuits/SET_CACHED', forCircuits)
        }
        if (forPipes.length > 0) {
            this.$store.commit('entities/pipes/SET_CACHED', forPipes)
        }
    }

    getPointByTime(key, time) {
        const item = this.loadedItems[key]
        time = +moment(time)
        const startTime = item.startTime
        const resolution = item.resolution

        // the following code adjusts for DST gaps happening in Cache.fixDataForDst()
        const _startTime = moment(startTime)
        const _time = moment(time)
        if (_time.isBefore(_startTime)) return null
        const debug = false
        debug && console.log({
            _startTime,
            _startTimeR: _startTime.format(),
            _time,
            _timeR: _time.format(),
        })
        let d = 0
        if (_startTime.year() === _time.year()) { // startTime and queried time belong to same year
            if (!_startTime.isDST() && _time.isDST()) {
                d = -3.6e3 / resolution
                debug && console.log('a')
            } else if (_startTime.isDST() && !_time.isDST()) {
                d = 3.6e3 / resolution
                debug && console.log('b')
            }
        // startTime and time belong to different year
        } else if (!_startTime.isDST() && _startTime.month() <= 4 && _time.isDST()) { // _startTime is before april of year X, time is summer of year X + 1
            // d = -3.6e3 / resolution
            debug && console.log('c')
        } else if (_startTime.isDST() && _time.isDST()) {
            // do nothing
            debug && console.log('d')
        } else if (!_startTime.isDST() && _startTime.month() >= 10 && _time.isDST()) { // _startTime is after october of year X, time is in summer of year X + 1
            d = -3.6e3 / resolution
            debug && console.log('e')
        } else if (_startTime.isDST() && !_time.isDST()) {
            debug && console.log('f')
            d = 3.6e3 / resolution
        }
        debug && console.log(d)
        // end

        const index = (time - startTime) / (resolution * 1e3) - d
        // console.log(index)
        return item.data[index]
    }

    init() {
        // console.log('cache:init')

        if (!this.$auth.loggedIn) {
            console.warn('User is not authenticated.')
            return
        }

        if (this._initialised) {
            // console.warn('Cache is already initialised.')
            return
        }

        this._initialised = true

        // do some user based upates to the state
        // eg: set period > resolution to user's preferred
        const { customer_id: customerId } = this.$auth.user
        const DB_NAME = `readings_data_${customerId}`
        this.$storage = new StorageService({ name: DB_NAME })
        this.loading = true

        // we don't really use the 'preferred resolution' for now
        // since user can't actually change this and is conflicting with gas page
        // if (preferred_resolution) {
        //     this.period =  { resolution: preferred_resolution }
        // }

        // start polling
        // this.startPolling()

        this.loading = false
    }

    startPolling() {
        // console.log('cache:startPolling')
        if (this.pollingInterval) return
        this.pollingInterval = setInterval(() => {
            this.update()
        }, 15 * 60 * 1000) // every 15 minutes
    }

    update() {
        // console.log('cache:update:started')
        this.updating = true

        for (const key in this.loadedItems) {
            const item = this.loadedItems[key]
            const startTime = item.startTime
            const [unit, , entity, id] = key.split('_') // ugene_1800_pipes_5000
            this.fetchDataOnDemand({ key, unit, entity, id, startTime })
                .then(data => this.loadItem(key, data))
        }

        this.updating = false
        // console.log('cache:update:ended')
    }

    /**
     * Save unit collection data sets
     *
     * @param {String} unit
     * @param {Array} collection
     *
     * @returns {Array} promises
     */
    saveUnitData(unit, collection = []) {
        const promises = collection.map(async item => {
            const savedItem = this.updating && (await this.$storage.get(item.uid))
            if (savedItem) {
                // when we have no total
                if (!item.total) {
                    item.total = 0
                }
                item.pointsLength += savedItem.pointsLength
                item.total += savedItem.total
                item.data = savedItem.data.concat(item[unit])
                item.startTime = savedItem.startTime
            }

            // store data
            await this.$storage.set(item.uid, item)

            return item
        })

        return Promise.all(promises)
    }

    fixDataForDst(item, resolution, unit = 'data') {
        if (!unit) {
            throw new Error('Missing unit parameter')
        }

        if (!item.data.length || isString(resolution) || resolution === 60) {
            return item
        }

        const fix = item => {
            const resolutionMs = resolution * 1e3
            const timeFactor = 3.6e3 / resolution
            const data = item[unit]
            const { startTime, endTime } = item
            const DST_START_UNIX = DST_START_TIMES.map(timestamp => new Date(timestamp).getTime())
            const DST_END_UNIX = DST_END_TIMES.map(timestamp => new Date(timestamp).getTime())
            const dstStartIndexes = this._calculateDSTIndexes(DST_START_UNIX, startTime, endTime, resolutionMs)
            const dstEndIndexes = this._calculateDSTIndexes(DST_END_UNIX, startTime, endTime, resolutionMs)
            const dstData = []

            for (let i = 0; i < data.length; i++) {
                if (dstStartIndexes.includes(i)) {
                    // Add empty null period * timeFactor
                    dstData.push(...Array.from({ length: timeFactor }).map(() => null))
                } else if (dstEndIndexes.includes(i)) {
                    // Skip values for timeFactor
                    i = i + timeFactor
                }
                dstData.push(data[i])
            }
            return dstData
        }

        let fixedData
        if (typeof item.data[0] === 'object') {
            fixedData = item.data.map(data => {
                return { ...data, [unit]: fix(data, resolution) }
            })
            return { ...item, data: fixedData }
        } else {
            fixedData = fix(item.data)
            return { ...item, [unit]: fixedData }
        }
    }

    _calculateDSTIndexes(timestamps, startTime, endTime, resolutionMs) {
        return timestamps
            // filter out DST events outside relevant range
            .filter(timestamp => timestamp >= startTime && timestamp <= endTime)
            // calculate the index of the DST event
            .map(dstEventTime => (dstEventTime - startTime) / resolutionMs)
    }

    /**
     * Fetch data on demand
     */
    async fetchDataOnDemand({ key, unit, entity, id, startTime, endTime, resolution, scopes }) {
        this.concurrentDemandLoading++

        if (!this._queue[key]) {
            const promise = () => this.fetchEntityData({
                entity,
                id,
                unit,
                startTime,
                endTime,
                resolution,
                scopes,
            })
            this._queue[key] = this.$queue.add(promise)
        }

        const data = await this._queue[key]
        this._queue[key] = undefined
        this.concurrentDemandLoading--
        return data
    }

    legacyAdapter({ id, entity, res, unit, multiplier }) {
        const unitParam = Unit.idToParam(unit)
        try {
            let resData = res.data[unitParam]
            if (!Array.isArray(resData)) {
                resData = {
                    x: keys(resData),
                    y: values(resData).map(e => (isNumber(e) ? float(e * multiplier) : null)),
                }
            } else {
                resData = resData.map(e => (isNumber(e) ? float(e * multiplier) : null))
            }
            return {
                data: [
                    {
                        id,
                        entity,
                        endTime: +moment(res.to),
                        [unit]: resData,
                        pointsLength: res.recordCount,
                        startTime: +moment(res.from),
                        total: res.total || sum(resData),
                    },
                ],
                interval: isNaN(res.resolution) ? res.resolution : Number(res.resolution),
                lastModified: res.last_modified || +moment(),
                status: 'success',
            }
        } catch (err) {
            console.warn(err.message)
        }
    }

    /**
     * Fetch unit data for entity
     *
     *
     * @param {String} unit
     * @param {String} entity
     * @param {Number} id
     *
     * @param startTime
     * @param endTime
     * @param resolution
     * @return {Promise} data
     */
    async fetchEntityData({ unit, entity, id, startTime, endTime, resolution, scopes }) {
        resolution = resolution || this.resolution

        const daterange = [
            (startTime ? moment.parseZone(startTime).utc() : MIN_MOMENT).format(),
            endTime ? moment(endTime).format() : moment().format(),
        ]
        const _unit = Unit.find(unit)
        const multiplier = _unit.multiplier
        const payload = {
            resolution,
            daterange,
            fields: [Unit.idToParam(unit)],
            [idKey(entity)]: [id],
            multiplier,
            with_timestamps: ['month', 'year'].includes(resolution),
            scopes,
        }

        let res = await this.$api.clickHouse.fetchReadings(payload)
        res = this.legacyAdapter({ id, entity, res, unit, multiplier })

        if (res && Array.isArray(res.data)) {
            res = this.fixDataForDst(res, resolution, unit)
            const data = this._prepareDataForSaving(unit, res.data, resolution, scopes)
            this.saveUnitData(unit, data)
            return data[0]
        }
    }

    async getUnitData(unit, model, commodity, resolution, startTime, endTime) {
        resolution = resolution || this.resolution
        const scopes = commodity === COMMODITY_TYPES.electricity
            ? ['ELEC']
            : [commodity?.toUpperCase()]
        // v2 readings api doesn't accept 'V123' ids
        if (model && model.virtual && model.presetId && model.parent) {
            model = model.parent
        }
        const { entity, id } = model
        const key = generateCacheKey([unit, resolution, entity, id])
        const lastLoadingAttempt = this._loadingAttempts[key] || 0
        const oldDataThreshold = +moment().subtract(6, 'hours')
        if (resolution === VALID_RESOLUTIONS.MINUTE) { // minute resolution data is not cached
            const data = await this.fetchDataOnDemand({
                key,
                unit,
                entity,
                id,
                startTime,
                endTime,
                resolution,
                scopes,
            })
            if (!data) {
                console.warn('Could not get data for: ', { key, unit, entity, id })
            }
            return data
        } else if (!this.loadedItems[key] ||
            (this.loadedItems[key].endTime < oldDataThreshold && lastLoadingAttempt < moment().subtract(15, 'minutes'))
        ) {
            this._loadingAttempts[key] = +moment()
            let data = await this.$storage.get(key)
            if (!data || data.endTime < oldDataThreshold) {
                data = await this.fetchDataOnDemand({
                    key,
                    unit,
                    entity,
                    id,
                    startTime: model.startTime,
                    resolution,
                    scopes,
                })
            }

            if (data) {
                this.loadItem(key, data)
            } else {
                console.warn('Could not get data for: ', { key, unit, entity, id })
            }
        }
        return this.loadedItems[key]
    }

    unloadEntityData(entity, id) {
        for (const key in this.loadedItems) {
            if (key.includes(generateCacheKey([entity, id]))) {
                this.unloadItem(key)
            }
        }
    }

    async destroy() {
        // console.log('cache:destroy ')
        // clear interval
        clearInterval(this.pollingInterval)
        this.loadedItems = {}
        this._queue = {}
        this._concurrentDemandLoading = 0
        this._initialised = false
        // remove cached data
        this.$localStorage.destroy()
        if (this.$storage) {
            await this.$storage.destroy()
        }
        await this.$store.dispatch('entities/deleteAll')
    }
}
