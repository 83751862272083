import pluralize from 'pluralize'
import { map } from 'lodash'
import { VALID_RESOLUTIONS } from './resolution'
import moment from '~/composables/useMoment'

export const MINUTE = 60
export const _5_MINUTES = MINUTE * 5
export const _15_MINUTES = MINUTE * 15
export const _30_MINUTES = MINUTE * 30
export const MIN_RESOLUTION = _30_MINUTES
export const HOUR = MINUTE ** 2
export const SIX_HOURS = HOUR * 6
export const HALF_DAY = HOUR * 12
export const DAY = HOUR * 24
export const WEEK = DAY * 7
export const MONTH = WEEK * 4
export const YEAR = DAY * 365 // WEEK * 52.14
export const EVER = YEAR * 100
export const ITEMS_PER_PAGE = 25
export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const FORMAT_TIME = 'HH:mm' // 00:00
export const FORMAT_DAY = 'ddd Do MMM' // Mon 1st Jan
export const FORMAT_WEEK = 'wo' // 1st
export const FORMAT_MONTH = 'MMM YYYY' // Jan 21
export const FORMAT_YEAR = 'YYYY' // 2021
export const DEFAULT_TIMEZONE = 'Europe/London'
export const EPOCHALYPSE = '2038-01-19 03:14:07'

// Hardcoded values to avoid calculating each event
export const DST_START_TIMES = [
    '2016-03-27T01:00:00Z',
    '2017-03-26T01:00:00Z',
    '2018-03-25T01:00:00Z',
    '2019-03-31T01:00:00Z',
    '2020-03-29T01:00:00Z',
    '2021-03-28T01:00:00Z',
    '2022-03-27T01:00:00Z',
    '2023-03-26T01:00:00Z',
    '2024-03-31T01:00:00Z',
    '2025-03-30T01:00:00Z',
    '2026-03-29T01:00:00Z',
    '2027-03-28T01:00:00Z',
    '2028-03-26T01:00:00Z',
    '2029-03-25T01:00:00Z',
    '2030-03-31T01:00:00Z',
    '2031-03-30T01:00:00Z',
    '2032-03-28T01:00:00Z',
    '2033-03-27T01:00:00Z',
    '2034-03-26T01:00:00Z',
    '2035-03-25T01:00:00Z',
    '2036-03-30T01:00:00Z',
    '2037-03-29T01:00:00Z',
    '2038-03-28T01:00:00Z',
    '2039-03-27T01:00:00Z',
    '2040-03-25T01:00:00Z',
]

export const DST_END_TIMES = [
    '2016-10-30T01:00:00Z',
    '2017-10-29T01:00:00Z',
    '2018-10-28T01:00:00Z',
    '2019-10-27T01:00:00Z',
    '2020-10-25T01:00:00Z',
    '2021-10-31T01:00:00Z',
    '2022-10-30T01:00:00Z',
    '2023-10-29T01:00:00Z',
    '2024-10-27T01:00:00Z',
    '2025-10-26T01:00:00Z',
    '2026-10-25T01:00:00Z',
    '2027-10-31T01:00:00Z',
    '2028-10-29T01:00:00Z',
    '2029-10-28T01:00:00Z',
    '2030-10-27T01:00:00Z',
    '2031-10-26T01:00:00Z',
    '2032-10-31T01:00:00Z',
    '2033-10-30T01:00:00Z',
    '2034-10-29T01:00:00Z',
    '2035-10-28T01:00:00Z',
    '2036-10-26T01:00:00Z',
    '2037-10-25T01:00:00Z',
    '2038-10-31T01:00:00Z',
    '2039-10-30T01:00:00Z',
    '2040-10-28T01:00:00Z',
]

const DST_START_UNIX = DST_START_TIMES.map(timestamp => new Date(timestamp).getTime())
const DST_END_UNIX = DST_END_TIMES.map(timestamp => new Date(timestamp).getTime())

/**
 * Week Days generator
 *
 * @param {Object} { offset, format }
 * @returns {Array}
 */
export function weekDays({ offset = 1, format = 'ddd' } = {}) {
    // could also use moment.weekdaysShort() and then just flip 'Sun' from start to end
    return Array.from({ length: 7 }).map((v, i) =>
        moment()
            .isoWeekday(i + offset)
            .format(format),
    )
}

/**
 * Date parser and formater
 * @param {Date|String|Number|Moment} date
 * @param {String} format
 */
export function date(date, format = DATE_FORMAT) {
    return moment(date).format(format)
}

/**
 * Utc date parser and formater
 * @param {Date|String|Number} date
 * @param {String} format
 */
export function utcDate(date, format = DATE_FORMAT) {
    return moment(date).format(format)
}

/**
 * @name isOfficeHours
 * @category Date Helpers
 * @description Check if the local time is between office hours
 *
 * @returns {Boolean}
 */
export function isOfficeHours() {
    const format = 'hh:mm:ss'
    const now = moment()
    const start = moment('09:00', format)
    const end = moment('17:00', format)
    return now.isBetween(start, end)
}

export function isAfter(startTime, endTime) {
    return moment(startTime).isAfter(endTime)
}

export function isBefore(startTime, endTime) {
    return moment(startTime).isBefore(endTime)
}

/**
 * @name datesArray
 * @category Date Helpers
 * @description Creates an array for a time range.
 * @param {*} params
 *
 * @returns
 */
export function datesArray({ start, end, resolution }) {
    const dates = []
    const startDate = moment(start)
    while (startDate.isBefore(end, resolution)) {
        dates.push(startDate.toDate())
        startDate.addResolution(resolution)
    }
    // if (resolution === 'day' && isToday(end.toDate())) {
    //     dates.push(end.clone().startOf(resolution).toDate())
    // }
    return dates
}

export function timesArray(startTime = '00:00:00', endTime = '24:00:00', resolution = MIN_RESOLUTION) {
    const times = []
    startTime = moment(startTime, 'HH:mm:ss')
    endTime = moment(endTime, 'HH:mm:ss')
    while (startTime.isBefore(endTime, 'second')) {
        times.push(startTime.format('HH:mm:ss'))
        startTime.add(resolution, 's')
    }
    return times
}

/**
 * dateTime {Number} unix timestamp
 * roundingValue {Number} minutes value in seconds
 */
export function snapDateTime(dateTime, roundingValue = 900) {
    roundingValue /= 60
    const dt = moment(dateTime)
    const minutes = dt.minutes()
    const newMinutes = Math.round(minutes / roundingValue) * roundingValue
    if (newMinutes === 60) {
        return dt.add(1, 'hour').minutes(0).seconds(0).milliseconds(0).valueOf()
    } else {
        return dt.minutes(newMinutes).seconds(0).milliseconds(0).valueOf()
    }
}

/**
 * Date radius
 * @param  {Date} date
 * @param  {Number} radius - in seconds
 * @return {Object} { start, end }
 */
export function dateRadius(date, radius = 0) {
    return {
        start: moment(date).subtract(radius, 'seconds'),
        end: moment(date).add(radius, 'seconds'),
    }
}

/**
 * @name  difference
 * @category Milisecond Helpers
 * @description  Get the number of full miliseconds between the given dates.
 *
 * @param  {Date|Number} dateLeft - the later date
 * @param  {Date|Number} dateRight - the earlier date
 * @return  {Number} the number of full miliseconds
 */
export function difference(dateLeft, dateRight) {
    return moment(dateLeft).diff(moment(dateRight))
}

/**
 * @name  differenceInSeconds
 * @category  Second Helpers
 * @description  Get the number of full seconds between the given dates.
 *
 * @param  {Date|Number} dateLeft - the later date
 * @param  {Date|Number} dateRight - the earlier date
 * @return  {Number} the number of full seconds
 */
export function differenceInSeconds(dateLeft, dateRight) {
    return moment(dateLeft).diff(moment(dateRight), 'seconds')
}

/**
 * @name  differenceInMinutes
 * @category  Minute Helpers
 * @description  Get the number of full minutes between the given dates.
 *
 * @param  {Date|Number} dateLeft - the later date
 * @param  {Date|Number} dateRight - the earlier date
 * @return  {Number} the number of full minutes
 */
export function differenceInMinutes(dateLeft, dateRight) {
    return moment(dateLeft).diff(moment(dateRight), 'minutes')
}

/**
 * @name  differenceInHours
 * @category  Hour Helpers
 * @description  Get the number of full hours between the given dates.
 *
 * @param  {Date|Number} dateLeft - the later date
 * @param  {Date|Number} dateRight - the earlier date
 * @return  {Number} the number of full hours
 */
export function differenceInHours(dateLeft, dateRight) {
    return moment(dateLeft).diff(moment(dateRight), 'hours')
}

export function differenceInFullHours(dateLeft, dateRight) {
    return differenceInHours(moment(dateLeft).startOf('hour'), dateRight)
}

/**
 * @name  differenceInDays
 * @category  Day Helpers
 * @description  Get the number of full days between the given dates.
 *
 * @param  {Date|Number} dateLeft - the later date
 * @param  {Date|Number} dateRight - the earlier date
 * @return  {Number} the number of full days
 */
export function differenceInDays(dateLeft, dateRight) {
    return moment(dateLeft).diff(moment(dateRight), 'days')
}

export function differenceInFullDays(dateLeft, dateRight) {
    return differenceInDays(moment(dateLeft).startOf('day'), dateRight)
}

/**
 * @name  differenceInWeeks
 * @category  Week Helpers
 * @description  Get the number of full weeks between the given dates.
 *
 * @param  {Date|Number} dateLeft - the later date
 * @param  {Date|Number} dateRight - the earlier date
 * @return  {Number} the number of full weeks
 */
export function differenceInWeeks(dateLeft, dateRight) {
    return moment(dateLeft).diff(moment(dateRight), 'weeks')
}

/**
 * @name differenceInFullWeeks
 * @category Week Helpers
 * @summary Get the number of full weeks between the given dates.
 *
 * @description
 * Get the number of full weeks between two dates. Fractional weeks are
 * truncated towards zero.
 *
 *
 * @param {Date|Number} dateLeft - the later date
 * @param {Date|Number} dateRight - the earlier date
 * @returns {Number} the number of full weeks
 */
export function differenceInFullWeeks(dateLeft, dateRight) {
    return differenceInWeeks(moment(dateLeft).startOf('isoWeek'), dateRight)
}

/**
 * @name  differenceInMonths
 * @category  Month Helpers
 * @description  Get the number of full months between the given dates.
 *
 * @param  {Date|Number} dateLeft - the later date
 * @param  {Date|Number} dateRight - the earlier date
 * @return  {Number} the number of full months
 */
export function differenceInMonths(dateLeft, dateRight) {
    return moment(dateLeft).diff(moment(dateRight), 'months')
}

/**
 * @name  differenceInFullMonths
 * @category  Month Helpers
 * @description  Get the number of full months between the given dates.
 *
 * @param  {Date|Number} dateLeft - the later date
 * @param  {Date|Number} dateRight - the earlier date
 * @return  {Number} the number of full months
 */
export function differenceInFullMonths(dateLeft, dateRight) {
    return differenceInMonths(moment(dateLeft).startOf('month'), dateRight)
}

/**
 * @name  differenceInYears
 * @category  Month Helpers
 * @description  Get the number of full years between the given dates.
 *
 * @param  {Date|Number} dateLeft - the later date
 * @param  {Date|Number} dateRight - the earlier date
 * @return  {Number} the number of full years
 */
export function differenceInYears(dateLeft, dateRight) {
    return moment(dateLeft).diff(moment(dateRight), 'years')
}

/**
 * @name  differenceInFullYears
 * @category  Month Helpers
 * @description  Get the number of full years between the given dates.
 *
 * @param  {Date|Number} dateLeft - the later date
 * @param  {Date|Number} dateRight - the earlier date
 * @return  {Number} the number of full years
 */
export function differenceInFullYears(dateLeft, dateRight) {
    return differenceInYears(moment(dateLeft).startOf('year'), moment(dateRight))
}

/**
 * @name  pointToTime
 * @category  Time Helpers
 * @description  Get the number of full years between the given dates.
 *
 * @param  {Object} { start, point, resolution }
 * @return  {Number} the date in ms
 */
export function pointToTime({ start, point, resolution }) {
    return pointToDate({ start, point, resolution }).valueOf()
}

/**
 * @name pointToDate
 * @category  Time Helpers
 *
 * @param {Object} { start, point, resolution }
 * @returns {String} date
 */
export function pointToDate({ start, point, resolution }) {
    return moment(start).addResolution(resolution, point)
}

/**
 * @name dateToPoint
 *
 * @param {Object} { date, start, resolution }
 * @returns {Number} point
 */
export function dateToPoint({ date, start, resolution }) {
    start = moment(start)
    date = moment(date)

    const point = -1
    if (!start.isValid() || !date.isValid()) {
        console.warn('At least one of the given dates is not valid. ', { start, date, resolution })
        return point
    }

    return diffInPoints(date, start, resolution)
}

/**
 * @name diffInPoints
 *
 * @param {Date} dateLeft
 * @param {Date} dateRight
 * @param {Number} resolution
 * @returns {Number} number of points
 */
export function diffInPoints(dateLeft, dateRight, resolution) {
    return Math.ceil(differenceInSeconds(dateLeft, dateRight) / resolution)
}

/**
 * @name isValidTime
 *
 * @param { String } val
 * @returns { Boolean }
 */
export function isValidTime(val, format = 'HH:mm') {
    return moment(val, format).isValid()
}

export function tzDifference(date, timezone) {
    date = date || moment()
    const tz = moment.tz.guess()
    const browserTzOffset = moment(date).tz(tz).utcOffset()
    const userTzOffset = moment(date).tz(timezone).utcOffset()
    return browserTzOffset - userTzOffset
}

export function getTimezoneOffset(timezone) {
    const now = moment.utc()
    const offset = now.tz(timezone).utcOffset()
    return offset
}

export function hasDST(datetime) {
    return moment(datetime).month(7).isDST()
}

export function userHasDST() {
    return hasDST(moment().tz(moment.tz.guess()))
}

/**
 * @description Determines if the OS timezone observes daylight savings
 * Should return true for European countries and false for India
 * @returns {Boolean}
 */
export function systemTimezoneObservesDST() {
    const january = new Date(new Date().getFullYear(), 0, 1)
    const july = new Date(new Date().getFullYear(), 6, 1)
    return january.getTimezoneOffset() !== july.getTimezoneOffset()
}

/**
 * @description Determines the percise indexes of DST events
 * @returns {Boolean}
 */
export function getDSTIndexes(startTime, endTime, resolution) {
    const resolutionMs = resolution * 1e3
    const dstStartIndexes = _calculateDSTIndexes(DST_START_UNIX, startTime, endTime, resolutionMs)
    const dstEndIndexes = _calculateDSTIndexes(DST_END_UNIX, startTime, endTime, resolutionMs)
    return { dstStartIndexes, dstEndIndexes }
}

function _calculateDSTIndexes(timestamps, startTime, endTime, resolutionMs) {
    return timestamps
        // filter out DST events outside relevant range
        .filter(timestamp => timestamp >= startTime && timestamp <= endTime)
        // calculate the index of the DST event
        .map(dstEventTime => (dstEventTime - startTime) / resolutionMs)
}

export function dateFormatByResolution(resolution) {
    if (isNaN(resolution)) {
        switch (true) {
            case resolution === VALID_RESOLUTIONS.DAY:
                return FORMAT_DAY
            case resolution === VALID_RESOLUTIONS.WEEK:
                return FORMAT_DAY
            case resolution === VALID_RESOLUTIONS.MONTH:
                return FORMAT_MONTH
            case resolution === VALID_RESOLUTIONS.YEAR:
                return FORMAT_YEAR
            default:
                return DATE_FORMAT
        }
    }

    switch (true) {
        case resolution <= HOUR:
            return FORMAT_TIME
        case resolution <= DAY:
            return FORMAT_DAY
        default:
            return DATE_FORMAT
    }
}

/**
 * @name isSameDay
 * @category Day Helpers
 * @summary Are the given dates in the same day?
 *
 * @description
 * Are the given dates in the same day?
 *
 * @param {Date|Number} dateLeft - the first date to check
 * @param {Date|Number} dateRight - the second date to check
 * @returns {Boolean} the dates are in the same day
 * @throws {TypeError} 2 arguments required
 *
 * @example
 * // Are 4 September 06:00:00 and 4 September 18:00:00 in the same day?
 * var result = isSameDay(new Date(2014, 8, 4, 6, 0), new Date(2014, 8, 4, 18, 0))
 * //=> true
 */
export function isSameDay(dirtyDateLeft, dirtyDateRight) {
    const dateLeftStartOfDay = moment(dirtyDateLeft).startOf('day')
    const dateRightStartOfDay = moment(dirtyDateRight).startOf('day')
    return dateLeftStartOfDay.valueOf() === dateRightStartOfDay.valueOf()
}

/**
 * @name isToday
 * @category Day Helpers
 * @summary Is the given date today?
 * @pure false
 *
 * @description
 * Is the given date today?
 *
 * @param {Date|Number} date - the date to check
 * @returns {Boolean} the date is today
 * @throws {TypeError} 1 argument required
 *
 * @example
 * // If today is 6 October 2014, is 6 October 14:00:00 today?
 * var result = isToday(new Date(2014, 9, 6, 14, 0))
 * //=> true
 */
export function isToday(dirtyDate) {
    return isSameDay(dirtyDate, moment())
}

/**
 * @name isStartOfYear
 * @category Date Helpers
 * @summary Is the given date at the begining of the year?
 *
 * @description
 * Is the given date at the begining of the year?
 *
 * @param {Date|Number} date - the date to check
 * @returns {Boolean} the date is today
 */
export function isStartOfYear(dirtyDate) {
    return moment(dirtyDate).isSame(moment(dirtyDate).startOf('year'))
}

/**
 * @name isYTD
 * @category Date Helpers
 * @summary Are the given dates YTD (from begining of year till today) ?
 *
 * @description
 * Are the given dates YTD (from begining of year till today) ?
 *
 * @param {Date|Number} dirtyDateLeft - the start date to check
 * @param {Date|Number} dirtyDateRight - the end date to check
 * @returns {Boolean} the dates are YTD
 */
export function isYTD(dirtyDateLeft, dirtyDateRight) {
    return isStartOfYear(dirtyDateLeft) && isToday(dirtyDateRight)
}

/**
 * @name isFullYear
 * @category Date Helpers
 * @summary Are the given dates a full year (from begining of year till end) ?
 *
 * @description
 * Are the given dates a full year (from begining of year till the end of the year) ?
 *
 * @param {Date|Number} dirtyDateLeft - the start date to check
 * @param {Date|Number} dirtyDateRight - the end date to check
 * @returns {Boolean} the dates makes a full year
 */
export function isFullYear(dirtyDateLeft, dirtyDateRight) {
    return differenceInFullYears(dirtyDateLeft, dirtyDateRight) === 1
}

/**
 * @name secondsToDuration
 * @category Date Helpers
 * @summary gives a text with the duration from number of seconds
 *
 * @description
 * gives a text with the duration from number of seconds
 *
 * @param {Number} seconds - the number of seconds
 * @returns {String} the duration
 */
export function secondsToDuration(seconds = 0) {
    const days = Math.floor(seconds / DAY)
    let remainingSeconds = seconds % DAY
    const hours = Math.floor(remainingSeconds / HOUR)
    remainingSeconds = remainingSeconds % HOUR
    const minutes = Math.floor(remainingSeconds / MINUTE)
    remainingSeconds = remainingSeconds % MINUTE

    let res = ''
    if (days > 0) {
        res = `${days}d`
    }
    if (hours > 0) {
        res += ` ${hours}h`
    }
    if (minutes > 0) {
        res += ` ${minutes}m`
    }
    return res
}

/**
 * @name sortDates
 * @category Date Helpers
 * @summary sorts and array of dates in ascendent order
 *
 * @description
 * sorts and array of dates in ascendent order
 *
 * @param {Array} dates - the array of dates
 * @returns {Array} sorted dates array
 */
export function sortDates(dates = []) {
    return [...dates].map(v => moment(v)).sort((a, b) => {
        if (a.isAfter(b)) {
            return 1
        }
        if (a.isBefore(b)) {
            return -1
        }
        return 0
    })
}

/**
 * @name humanizeDuration
 *
 * @param   {Date}  dateLeft
 * @param   {Date}  dateRight
 * @param   {Boolean} detailed
 *
 * @return  {String}
 */
export function humanizeDuration(dateLeft, dateRight, detailed = false) {
    if (detailed) {
        const values = durationValues(dateLeft, dateRight)
        const mappedValues = map(values, (v, k) => {
            if (v === 0) return []
            if (v === 1) return `${v} ${pluralize.singular(k)}`
            return `${v} ${k}`
        }).flat()
        // limit to 2 items
        mappedValues.splice(2)
        // const [lastItem] = mappedValues.splice(-1)
        // const res = mappedValues.join(', ')
        // return res ? `${res} and ${lastItem}` : lastItem
        return mappedValues.join(', ')
    }
    return moment.duration(difference(dateLeft, dateRight)).humanize()
}

/**
 * @name durationValues
 * @category Date Helpers
 * @summary gives an object with the duration values as props
 *
 * @description
 * gives an object with the duration values as props
 *
 * @param   {Date}  dateLeft
 * @param   {Date}  dateRight
 * @returns {Object} durationValues
 */
export function durationValues(dateLeft, dateRight) {
    const values = {
        years: 0,
        months: 0,
        weeks: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    }

    const date = moment(dateLeft)

    const years = differenceInYears(dateRight, date)
    if (years > 0) {
        values.years = years
        date.add(years, 'year')
    }

    const months = differenceInMonths(dateRight, date)
    if (months > 0) {
        values.months = months
        date.add(months, 'month')
    }

    const weeks = differenceInWeeks(dateRight, date)
    if (weeks > 0) {
        values.weeks = weeks
        date.add(weeks, 'week')
    }

    const days = differenceInDays(dateRight, date)
    if (days > 0) {
        values.days = days
        date.add(days, 'day')
    }

    const hours = differenceInHours(dateRight, date)
    if (hours > 0) {
        values.hours = hours
        date.add(hours, 'hour')
    }

    const minutes = differenceInMinutes(dateRight, date)
    if (minutes > 0) {
        values.minutes = minutes
        date.add(minutes, 'minute')
    }

    const seconds = differenceInSeconds(dateRight, date)
    if (seconds > 0) {
        values.seconds = seconds
    }

    return values
}

/**
 * Check if the given date range is a fixed range
 * ie: exact calendar day, week or month or year
 *
 * @param {Date}  dateLeft
 * @param {Date}  dateRight
 *
 * @return {Boolean} isFixedRange
 */
export function isFixedRange(dateLeft, dateRight) {
    const duration = moment.duration(difference(dateRight, dateLeft))

    switch (1) {
        case duration.asHours(): // 1 hour
        case duration.asDays(): // 1 day
        case duration.asWeeks(): // 1 week
            return true
    }

    const monthDayCounts = [28, 29, 30, 31]
    if (monthDayCounts.includes(duration.asDays())) { // 1 month
        return true
    }

    if (monthDayCounts.includes((duration.asHours() - 1) / 24)) { // last month, last 30 days
        return true
    }

    if ([365, 366].includes(duration.asDays())) { // 1 year
        return true
    }

    return false
}

/**
 * Get all months
 *
 * @return {Array} months
 */
export function months() {
    return moment.months().map((text, idx) => ({ text, value: idx + 1 }))
}

/**
 * Get all the days in a month
 *
 * @param {Number}  month
 *
 * @return {Array} days
 */
export function days(month) {
    return Array.from({
        length: moment(month, 'MM')
            .daysInMonth(),
    }, (_, i) => i + 1)
}
