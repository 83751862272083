/** * ORM ***/
import download from 'downloadjs'
import Unit from '~/orm/models/Unit'
/** * Utils ***/
import { eliberate, FROM_ROOT } from '~/utils/state'
import { COMMODITY_TYPES } from '~/utils/entities'

export default {
    /**
     * Initialise the store
     * Ideally called from the gas page
     * @param  {Function} options.commit
     * @param  {Function} options.dispatch
     * @return {Void}
     */
    async init({ commit, dispatch, getters, state, rootState, rootGetters }) {
        // start loading
        if (!state.loading) {
            commit('SET_LOADING', true)
        }

        // init cache
        await this.$cache.init()

        // setup sites and pipes
        await Promise.all([
            dispatch('entities/sites/setup', {}, FROM_ROOT),
            dispatch('entities/pipes/setup', {}, FROM_ROOT),
            dispatch('entities/pipes/setupVirtual', {}, FROM_ROOT),
        ])

        if (!getters.supported) {
            console.warn('Gas feature is not supported atm.')
            return
        }

        // init period
        const firstInTime = rootGetters['entities/pipes/firstData']
        const lastInTime = rootGetters['entities/pipes/lastData']
        const initPeriod = {
            min: this.$_.get(firstInTime, 'startTime'),
            max: this.$_.get(lastInTime, 'endTime'),
        }
        if (firstInTime || lastInTime) {
            await dispatch('period/init', initPeriod, FROM_ROOT)
        }
        // update period resolution
        const initialResolution = rootGetters['entities/pipes/initialResolution'] || 86400
        if (rootState.period.resolution !== initialResolution) {
            await dispatch('period/update', { resolution: initialResolution }, FROM_ROOT)
        }
        // set tree items for tree views
        await dispatch('setTreeviews')

        // init unit
        dispatch('initUnit')

        // reset comparison ? (CVP-1619)
        if (state.comparison) {
            dispatch('setComparison', null)
        }

        // select sites
        if (rootState.selectedSiteIds.length > 0) {
            getters.treeview.selectSites(rootState.selectedSiteIds)
        }

        commit('SET_SELECTED_PARAMETERS_PRESET', rootGetters['entities/parameters-presets/default']('gas'))

        // stop loading
        commit('SET_LOADING', false)

        // init
        commit('SET_INITIALISED', true)
    },

    initUnit({ commit }) {
        const unitIdFromHash = this.$_.get(this.$hashState, 'params.unit', null)
        const unit = Unit.find(unitIdFromHash)
        if (unit && unit.scope === 'gas') {
            commit('SET_SELECTED_UNIT', unitIdFromHash)
        }
    },

    /**
     * Setup the treeviews
     *
     * @param {Function} commit
     * @param {Object} rootGetters
     *
     * @returns {Void}
     */
    async setTreeviews({ commit, rootGetters }) {
        const treeviews = this.$db().model('treeviews').for('gas')
        let initialTreeview = treeviews[0]
        // consider initial state from #hash or user bookmarks or user bookmarks
        const treeview = this.$hashState.gasTreeview || this.$_.get(rootGetters.userState, 'gasTreeview')
        const userStateTreeview = treeview ? rootGetters['entities-manager/validatedTreeview'](treeview) : null
        // init treeviews
        for (const tv of treeviews) {
            if (userStateTreeview?.type === tv.id) {
                tv.init(userStateTreeview)
                initialTreeview = tv
            } else {
                await tv.init()
            }
        }
        // set active treeview
        commit('entities/treeviews/SET_ID', initialTreeview.id, FROM_ROOT)

        // set treeviews as plain objects in the state to ease access globally
        commit(
            'SET_TREEVIEWS',
            treeviews.map(tv => tv.toJSON()),
        )
    },

    /**
     * Set tab index
     * @param {Function} options.commit
     * @param {Number} value
     */
    setTabIndex({ commit }, value) {
        value = String(value)
        if (!['0', '1'].includes(value)) {
            return // invalid index
        }
        commit('SET_TAB_INDEX', value)
    },

    toggleAnalysisFeature({ commit, dispatch, rootState }, payload) {
        commit('TOGGLE_ANALYSIS_FEATURE', payload)

        // when activating Max Demands
        if (payload.key === 'maxDemands' && payload.active) {
            dispatch(
                'period/update',
                {
                    start: rootState.period.min,
                    end: rootState.period.max,
                },
                FROM_ROOT,
            )
        }
    },

    setSelectedUnit({ commit }, unit) {
        commit('SET_SELECTED_UNIT', unit)
    },

    addSnapshot({ commit }, v) {
        commit('ADD_SNAPSHOT', { ...v, id: this.$_.uniqueId('snapshot-') })
    },

    selectSnapshot({ commit, state }, v) {
        commit(
            'SET_SNAPSHOTS',
            state.snapshots.map(snapshot => {
                return {
                    ...snapshot,
                    selected: snapshot.id === v.id,
                }
            }),
        )
    },

    setSelectedPoint({ commit }, v) {
        commit('SET_SELECTED_POINT', v)
    },

    toggleExtremePoint({ dispatch, state }, extreme = 'max') {
        let point
        if (state.selectedPoint && state.selectedPoint.extreme === extreme) {
            point = null
        } else {
            point = {
                extreme,
                uid: this.$_.uniqueId('point_'),
            }
        }
        dispatch('setSelectedPoint', point)
    },

    toggleChartAutoYAxis({ commit, state }) {
        commit('SET_AUTO_Y_AXIS', !state.autoYAxis)
    },

    setChartAutoYAxis({ commit }, v) {
        commit('SET_AUTO_Y_AXIS', v)
    },

    setTriadsReadings({ commit }, v) {
        commit('SET_TRIADS_READINGS', v)
    },

    setLoadFactorData({ commit }, v) {
        commit('SET_LOAD_FACTOR_DATA', v)
    },

    setConsolidation({ commit }, value) {
        commit('SET_CONSOLIDATION', value)
    },

    toggleConsolidation({ commit, state }) {
        commit('SET_CONSOLIDATION', !state.consolidation)
    },

    setComparison({ commit }, value) {
        commit('SET_COMPARISON', value)
    },

    toggleComparison({ dispatch, state, rootGetters }) {
        const comparison = state.comparison ? null : this.$_.clone(rootGetters['period/comparison'])
        dispatch('setComparison', comparison)
    },

    toggleRag({ commit, state }, value = null) {
        commit('SET_RAG', this.$_.isBoolean(value) ? value : !state.ragEnabled)
    },

    async downloadJSON({ rootState, rootGetters }) {
        const { period } = rootState
        const customer = rootGetters['entities/customers/all']()[0]
        const { unit, resolution } = this.$_.get(customer, 'settings.downloadsEnabled.0', {})
        /**
         * Temporarily only support one unit
         */
        if (!(unit && resolution)) {
            return "You don't have the access to this feature"
        }
        const outputResolution = resolution / 60
        if (outputResolution < period.resolution / 60) {
            return `Found misconfiguration in the output resolution (${outputResolution}, ${period.resolution / 60})`
        }
        const allPipes = rootGetters['entities/pipes/haveData']
        const data = await Promise.allSettled(allPipes.map(c => c.getUnitData(unit, COMMODITY_TYPES.gas)))
        const output = []
        allPipes.forEach((pipe, k) => {
            if (data[k] && data[k].status === 'fulfilled') {
                const originalPipeData = eliberate(data[k].value.data)
                const startTime = this.$moment(data[k].value.startTime)
                // i (Tony :D) want to round it up to the closest hour
                const howManyPointsToSkip =
                    startTime.minutes() !== 0
                        ? 60 / (period.resolution / 60) - (60 / (period.resolution / 60)) * (startTime.minutes() / 60)
                        : 0
                startTime.add(howManyPointsToSkip * period.resolution, 'seconds')
                let pipeData = originalPipeData.splice(howManyPointsToSkip)
                pipeData = this.$_.chunk(pipeData, outputResolution / (period.resolution / 60)).map((e, k) => {
                    return {
                        dt: this.$moment(startTime)
                            .add(k * outputResolution, 'minutes')
                            .format(),
                        kwh: Number(this.$_.sum(e)).toFixed(4),
                    }
                })
                output.push({
                    site: pipe.relationships.site.name,
                    pipeName: pipe.pipeName,
                    data: pipeData,
                })
            }
        })
        download(
            JSON.stringify(output, null, 4),
            `${this.$moment().format('YYYY_MM_DD_HH_mm_ss')}_kwh_output.json`,
            'text/plain',
        )
        return true
    },

    setRenderedTraces({ commit }, value) {
        commit('SET_RENDERED_TRACES', value)
    },

    setAnnotationsMode({ commit }, value) {
        commit('SET_ANNOTATIONS_MODE', value)
    },

    reset({ commit, dispatch }, { hard = false } = {}) {
        dispatch('resetChart')
        dispatch('entities/treeviews/reset', {}, FROM_ROOT)
        dispatch('period/reset', { resolution: 1800 }, FROM_ROOT)
        this.$eventBus.$emit('hard-reset')

        if (hard) {
            commit('RESET')
        }
    },

    resetChart({ dispatch }) {
        dispatch('setSelectedPoint', null)
        dispatch('setComparison', null)
        dispatch('toggleRag', false)
        dispatch('setAnnotationsMode', false)
        dispatch('setChartAutoYAxis', false)
    },

    destroy({ commit }) {
        commit('SET_INITIALISED', false)
    },

    selectParametersPreset({ commit }, v) {
        commit('SET_SELECTED_PARAMETERS_PRESET', v)
    },
    updateNode({ commit, state }, node) {
        commit('UPDATE_NODE', node)
    },

    setTracesVisibility({ state, commit, getters }, traces) {
        const v = {
            ...state.tracesVisibility,
            ...traces,
        }
        commit('SET_TRACES_VISIBILITY', v)
    },

    toggleInspector({ state, commit }, value) {
        commit('SET_IS_INSPECTOR_VISIBLE', value ?? !state.isInspectorVisible)
    },

    checkActiveCircuits({ state, getters }) {
        // selectedParameterKeys doesn't exist till we enable multiparameters for gas
        if (getters.selectedPipesForced.length > 1 && state.selectedParameterKeys?.length > 1) {
            this.$toast.info('You can\'t select more than one circuit when two or more parameters are selected')
            return false
        }
        return true
    },
}
