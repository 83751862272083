import moment from '~/composables/useMoment'

export const initialState = (payload = {}) => ({
    initialised: false,
    start: moment().subtract(1, 'month').startOf('h'),
    end: moment().startOf('h'),
    min: moment().subtract(3, 'years').startOf('h'),
    max: moment().subtract(6, 'hours').startOf('h'),
    resolution: 15 * 60, // 15 min
    interval: null,
    shift: null,
    absoluteComparison: false,
    ...payload,
})

export default () => ({
    ...initialState(),
})
